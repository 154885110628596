import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {PageTitle, LoadingSK, ListData, AccountSecurity, DataTable, DomainMgt, ServerMgt, HostingMgt} from '../../components/utility/utility';
import { jwtDecode } from "jwt-decode";

import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import Form from '../../components/form/form';
import AddIcon from '@mui/icons-material/Add';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import KeyIcon from '@mui/icons-material/Key';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InventoryIcon from '@mui/icons-material/Inventory';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArticleIcon from '@mui/icons-material/Article';
import PagesIcon from '@mui/icons-material/Pages';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import GroupsIcon from '@mui/icons-material/Groups';
//import WorkIcon from '@mui/icons-material/Work';
import DomainIcon from '@mui/icons-material/Domain';
import WebIcon from '@mui/icons-material/Web';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Inventory2Icon from '@mui/icons-material/Inventory2';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaymentsIcon from '@mui/icons-material/Payments';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import EmailIcon from '@mui/icons-material/Email';
import ListIcon from '@mui/icons-material/List';
import SellIcon from '@mui/icons-material/Sell';
import ImageIcon from '@mui/icons-material/Image';
import MoneyIcon from '@mui/icons-material/Money';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';



import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircleIcon from '@mui/icons-material/Circle';

let curUser = null;
if(sessionStorage.getItem("signIn")){
curUser = jwtDecode(sessionStorage.getItem("signIn"));}


function SDate1(props){
const d = new Date(parseInt(props.item));
return d.toDateString();}


function ListNewTickets(props) { 
const getNewTickets = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:'138' , arg2:0, arg3:'support'}, fetchPolicy: "cache-and-network"});
let ticketDetails=<LoadingSK />;

const header = (
<thead>
<tr>
<th className='editCol'>&nbsp;</th>
<th>Date</th>
<th>Subject</th>
<th>Issue Type</th>
</tr>
</thead>
);


if(getNewTickets.data){
ticketDetails='';
}
if(getNewTickets.data && getNewTickets.data.getSiteList.length > 0){
const numbersTicketDetails = getNewTickets.data.getSiteList;
ticketDetails = numbersTicketDetails.map((number, index) => <tr className="p-2 pointer" key={`${window.uuidv4()}${number.id}`}>
<td className='editCol px-1 pointer' onClick={(e) => { window.modifyState(`/acct/114/editdata/${number.inid == 0 ? number.itemId : number.inid}/${number.tbl}`,'') }}>&#9998;</td><td><SDate1 item={number.date} /></td><td>{number.string4}</td><td>{number.string1}</td></tr> 
); 
return (<table className='customers'>{header}
<tbody>{ticketDetails}</tbody></table>);}
return (<table className='customers'>{header}
</table>);}




function TicketReply(props) { 
//const getTicketDetails = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'138', arg2:props.id, arg3:'getreplydetails' }, fetchPolicy: "cache-and-network" }); 
const getTicketDetails = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'138', arg2:parseInt(props.id), arg3:'getreplydetails' }, fetchPolicy: "cache-and-network" }); 
let ticketDetails=<LoadingSK />;

if(getTicketDetails.data && getTicketDetails.data.getSiteList.length > 0){
const numbersTicketDetails = getTicketDetails.data.getSiteList;
ticketDetails = numbersTicketDetails.map((number, index) => <section key={`${window.uuidv4()}yDetails${number.id}`}  className={`${parseInt(number.groupid) === 0 ? 'ticketdetailsme' : 'ticketdetails'}`}>
<p className='ticketowner'>{parseInt(number.groupid) === 0 ? 'Support' : 'Customer'} </p>
{parseInt(number.groupid) !== 0 ? <section>{number.string2}</section> : <section dangerouslySetInnerHTML={{__html: number.description}}></section>}
</section>); 
return ticketDetails;
}
return ticketDetails;
}

function TicketDetails(props) { 
const [formState, setFormState] = useState({ display: false, });
function updateDisplay(){
setFormState({ ...formState, display: !formState.display });
}
let ticketDetails='';
const numbersTicketDetails = props.tickets.filter(function(currItem) { return (parseInt(props.id) === parseInt(currItem.inid) || parseInt(props.id) === parseInt(currItem.itemId)); });
ticketDetails = numbersTicketDetails.map((number, index) => <tr key={`${window.uuidv4()}TicketDetails${number.id}`}>
<td colSpan={5} className='m-3'>
<span className={`d-block ${parseInt(curUser.userId) === parseInt(number.userid) ? 'ticketdetailsme' : 'ticketdetails'}`}>
<p className='ticketowner'>{parseInt(curUser.userId) === parseInt(number.userid) ? 'Me' : 'Support'} {props.id} {number.inid} {number.itemId} row {props.rowid}</p>
{parseInt(curUser.userId) === parseInt(number.userid) ? <section>{number.string2}</section> : <section dangerouslySetInnerHTML={{__html: number.description}}></section>}
</span></td>
</tr>);  




const header = (<tr>
<td className='editCol'><Tooltip title="Details" arrow={true}><MoreVertIcon onClick={() => { updateDisplay()}} className='pointer' /></Tooltip></td><td><SDate1 item={props.date} /></td><td>{props.string4}</td><td>{props.string1}</td><td><center>{parseInt(props.status) === 1 || parseInt(props.status) === 2 ? <Tooltip title="Active" arrow={true}><span className='pointer'><CircleIcon sx={{fontSize:12, color:'green', }} /></span></Tooltip> : <Tooltip title="Closed" arrow={true}><CircleIcon sx={{fontSize:12, color:'#BEBEC5', }} /></Tooltip>} </center></td>
</tr>);
return (<>{header}{formState.display === true ? ticketDetails : null}{formState.display === true ? <tr className='bg-white'><td colSpan={5} className='pt-5'>
<Form type="form" planid={curUser.userId} viewid={2} tbl={138} string4={props.string4} rowid={props.rowid} string1={props.string1} id={props.id} formmode={2} /></td></tr> : null}</>);
}

function icon(id) { 
switch(parseInt(id) ? parseInt(id) : 0) {
case 131: 
return (<PagesIcon sx={{ mr: 1, }} />);
case 132: 
case 110: 
return (<ArticleIcon sx={{ mr: 1, }} />);
case 133: 
return (<InventoryIcon sx={{ mr: 1, }} />);
case 138: 
return (<ContactSupportIcon sx={{ mr: 1, }} />);
case 136: 
return (<HandshakeIcon sx={{ mr: 1, }} />);
case 137: 
return (<GroupsIcon sx={{ mr: 1, }} />);
case 116: 
return (<DomainIcon sx={{ mr: 1, }} />);
case 117: 
return (<WebIcon sx={{ mr: 1, }} />);
case 118: 
return (<StorageIcon sx={{ mr: 1, }} />);
case 119: 
return (<SecurityIcon sx={{ mr: 1, }} />);
case 120: 
return (<HandshakeIcon sx={{ mr: 1, }} />);
case 38: 
return (<ReceiptIcon sx={{ mr: 1, }} />);
//case 142: 
//return (<CreditCardIcon sx={{ mr: 1, }} />);
case 141:
case 142: 
return (<PaymentsIcon sx={{ mr: 1, }} />);
case 143: 
return (<EmailIcon sx={{ mr: 1, }} />);
case 4: 
return (<KeyIcon sx={{ mr: 1, }} />);
case 5: 
return (<PermContactCalendarIcon sx={{ mr: 1, }} />);
case 11: 
return (<ListIcon sx={{ mr: 1, }} />);
case 6: 
return (<SellIcon sx={{ mr: 1, }} />);
case 8: 
return (<ImageIcon sx={{ mr: 1, }} />);
case 7: 
return (<WidgetsIcon sx={{ mr: 1, }} />);
case 145: 
return (<MenuIcon sx={{ mr: 1, }} />);
case 24: 
return (<ContactMailIcon sx={{ mr: 1, }} />);
case 23: 
return (<AccountTreeIcon sx={{ mr: 1, }} />);
default:
return null;
}}

let myPayments ='';



function getProductTotal(x,y){
let total = x.filter(function(currItem) { return parseInt(currItem.tbl) == parseInt(y); });
if(total.length>0){return `(${total.length})`;} else {return '';}
}

function getHeader(x){
switch(parseInt(x) ? parseInt(x) : 0) {
case 143: 
return (<thead><tr>
<th className='editCol'>&nbsp;</th><th>Created</th><th>Subject</th><th>Issue type</th><th><center>Status</center></th></tr></thead>);
case 141: 
return (<thead><tr><th className='editCol'>&nbsp;</th><th>Date</th><th>Invoice ID</th><th>Payment ID</th><th>Amount</th></tr></thead>);
case 139: 
return (<thead><tr><th className='editCol'>&nbsp;</th><th>Date</th><th>Invoice ID</th><th>Amount</th><th><center>Status</center></th></tr></thead>);
//case 140: 
case 120:
case 118:
case 116: 
case 117: 
return (<thead><tr>
<th className='editCol'>&nbsp;</th><th>Title</th><th>Created</th><th>Expires</th><th><center>Status</center></th></tr></thead>);
default:
return null;
}}


function a11yProps(index) { 
return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,}; }

function CustomTabPanel(props) {
const { children, value, index, ...other } = props;
return (
<div
role="tabpanel"
hidden={value !== index}
id={`simple-tabpanel-${index}`}
aria-labelledby={`simple-tab-${index}`}
{...other} >
{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
</div>); }

export default function Myacct(props) { 
let { itemId, mode, id, tbl } = useParams();


const [formState, setFormState] = useState({
display: '',
});



let buildForm = '';
const { pathname, state } = useLocation();
//const getTickets = useQuery(DEF_SITE_LIST, {variables: { listid: 138 }, fetchPolicy: "cache-and-network" }); 

const getTickets = useQuery(DEF_SITE_LIST, {variables: { listid: 138, arg1:'ticketdetails'}, fetchPolicy: "cache-and-network" }); 
const getInputValues = useQuery(DEF_SITE_LIST, {variables: { listid: (mode == 'editdata' ? 2 : 1), arg1:String(tbl), arg2:parseInt(itemId), }, fetchPolicy: "cache-and-network" }); 


if(getInputValues.data && getInputValues.data.getSiteList.length > 0){ 
buildForm = (<>{mode == 'editdata' ? 2 : 1} here {mode}
	planid 
<Form type="form" viewid={mode == 'editdata' ? 2 : 1} 
formmode={2} 
fullname={getInputValues.data.getSiteList[0].fullname}
gender={getInputValues.data.getSiteList[0].gender}
username={getInputValues.data.getSiteList[0].username}
frmtype={2}
formid={2}
status={getInputValues.data.getSiteList[0].status}
domain={getInputValues.data.getSiteList[0].domain}
description={getInputValues.data.getSiteList[0].description}
name={getInputValues.data.getSiteList[0].name}
password={getInputValues.data.getSiteList[0].password}
confirmPassword={getInputValues.data.getSiteList[0].confirmPassword}
address={getInputValues.data.getSiteList[0].address}
city={getInputValues.data.getSiteList[0].city}
province={getInputValues.data.getSiteList[0].province}
zip={getInputValues.data.getSiteList[0].zip}
country={getInputValues.data.getSiteList[0].country}
itemstatus={getInputValues.data.getSiteList[0].itemstatus}
rowid={(getInputValues.data.getSiteList[0].inid == 0) ? getInputValues.data.getSiteList[0].itemId : getInputValues.data.getSiteList[0].inid}
tbl={getInputValues.data.getSiteList[0].tbl}
phone={getInputValues.data.getSiteList[0].phone}
title={getInputValues.data.getSiteList[0].title}
id={getInputValues.data.getSiteList[0].itemId}
planid={getInputValues.data.getSiteList[0].planid}
uuid={getInputValues.data.getSiteList[0].uuid}
total={getInputValues.data.getSiteList[0].total}
planstate={getInputValues.data.getSiteList[0].planstate}

string1={getInputValues.data.getSiteList[0].string1}
string2={getInputValues.data.getSiteList[0].string2}
string3={getInputValues.data.getSiteList[0].string3}
string4={getInputValues.data.getSiteList[0].string4}
string5={getInputValues.data.getSiteList[0].string5}
string6={getInputValues.data.getSiteList[0].string6}
string7={getInputValues.data.getSiteList[0].string7}
string8={getInputValues.data.getSiteList[0].string8}
string9={getInputValues.data.getSiteList[0].string9}
string10={getInputValues.data.getSiteList[0].string10}

string11={getInputValues.data.getSiteList[0].string11}
string12={getInputValues.data.getSiteList[0].string12}
string13={getInputValues.data.getSiteList[0].string13}
string14={getInputValues.data.getSiteList[0].string14}
string15={getInputValues.data.getSiteList[0].string15}
string16={getInputValues.data.getSiteList[0].string16}
string17={getInputValues.data.getSiteList[0].string17}
string18={getInputValues.data.getSiteList[0].string18}
string19={getInputValues.data.getSiteList[0].string19}



string20={getInputValues.data.getSiteList[0].string20}
string21={getInputValues.data.getSiteList[0].string21}
string22={getInputValues.data.getSiteList[0].string22}
string23={getInputValues.data.getSiteList[0].string23}
string24={getInputValues.data.getSiteList[0].string24}
string25={getInputValues.data.getSiteList[0].string25}
string26={getInputValues.data.getSiteList[0].string26}
string27={getInputValues.data.getSiteList[0].string27}


 /></>);
 }
let myTickets='';
if(getTickets.data && getTickets.data.getSiteList.length > 0){

const numbersTickets = getTickets.data.getSiteList.filter(function(currItem) { return (parseInt(currItem.inid) === 0); });
myTickets = numbersTickets.map((number, index) => <tbody key={`${window.uuidv4()}sTickets${index}${number.id}`}>
<TicketDetails id={number.itemId} rowid={number.inid == 0 ? number.itemId : number.inid} date={number.date} string4={number.string4} string1={number.string1} status={number.status} tickets={getTickets.data.getSiteList} />
</tbody>
);
}

const numbersPrimary = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 115 && currItem.inid != 120 && currItem.itemId != 120; });
const numbersAdmin = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 129; });
const numbersBilling = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 3; });
const numbersAccountSettings = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 144; });
const numbersMyHosting = props.ownData.getSiteList.filter(function(currItem) { return parseInt(currItem.tbl) == 117; });
const numbersMyDomains = props.ownData.getSiteList.filter(function(currItem) { return parseInt(currItem.tbl) == 116; });
const numbersMyServers = props.ownData.getSiteList.filter(function(currItem) { return parseInt(currItem.tbl) == 118; });
const [selectedIndex, setSelectedIndex] = React.useState(1);
const handleListItemClick = (
event: React.MouseEvent<HTMLDivElement, MouseEvent>,
index: number, ) => { setSelectedIndex(index); };
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};

function updateDisplay(x){
	setFormState({ ...formState, display: (formState.display == x ? '' : x)});
	}

const [value, setValue] = React.useState(0);
const handleChangeTab = (event, newValue) => { setValue(newValue); };
function resetDisplay(newValue){setValue(newValue);}

let adminMenu = numbersAdmin.map((number, index) => <Link key={`${number.id}numbe${window.uuidv4()}min`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'><ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid)); resetDisplay(0); }}> {icon(number.inid == 0 ? number.itemId : number.inid)}<ListItemText primary={`${number.title} ${number.itemId}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myProducts = numbersPrimary.map((number, index) => <Link key={`${number.id}numbe${window.uuidv4()}Primary`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'><ListItem disablePadding dense
><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid));  resetDisplay(0);}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText primary={`${number.title} ${getProductTotal((props.ownData.getSiteList),(number.inid == 0 ? number.itemId : number.inid))}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myBilling = numbersBilling.map((number, index) => <Link key={`${number.id}nu${window.uuidv4()}mbersBilling`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'> <ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid));  resetDisplay(0);}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText primary={`${number.title}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myAccountSettings = numbersAccountSettings.map((number, index) => <Link key={`${number.id}numbersAccount${window.uuidv4()}Settings`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'><ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid));  resetDisplay(0);}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText primary={`${number.title}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myDomains='';
if(numbersMyDomains.length > 0){
myDomains = numbersMyDomains.map((number, index) => <tbody key={`myDomainList${number.id}`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { updateDisplay(`myDomainList${number.id}`)}} className='pointer' /></Tooltip></td><td>{number.title} {parseInt(number.inid) === 0 ? number.itemId : number.inid}</td><td>{number.domain}</td><td><SDate1 item={number.zip} /></td><td><center><Tooltip title="Active" arrow={true}><CircleIcon className='active' sx={{fontSize:12, }} /></Tooltip></center></td></tr>
{formState.display === `myDomainList${number.id}` ? <tr><td colSpan={5}>
<section><DomainMgt title={number.province} menu={props.menu} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} /></section>
</td></tr> : null}</tbody>);}

let myHosting='';
if(numbersMyHosting.length > 0){
myHosting = numbersMyHosting.map((number, index) => <tbody key={`${number.id}myHostingsdf`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon className='pointer' onClick={() => { updateDisplay(`${number.id}myHostingsdf`)}} /></Tooltip></td><td>{number.title}</td><td>{number.domain}</td><td><SDate1 item={number.zip} /> {parseInt(number.inid) === 0 ? number.itemId : number.inid}</td><td><center><Tooltip title="Active" arrow={true}><CircleIcon className='active' sx={{fontSize:12, }} /></Tooltip></center></td></tr>
{formState.display === `${number.id}myHostingsdf` ? <tr><td colSpan={5}>
<section><HostingMgt title={number.province} menu={props.menu} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} productFeatures={props.productFeatures} /></section>
</td></tr> : null}</tbody>);}

let myServers='';
if(numbersMyServers.length > 0){
myServers = numbersMyServers.map((number, index) => <tbody key={`${number.id}myServerslistk4kd`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { updateDisplay(`${number.id}myServerslistk4kd`)}} className='pointer' /></Tooltip></td><td>{number.title}</td><td>{number.domain}</td><td><SDate1 item={number.zip} /></td><td><center><Tooltip title="Active" arrow={true}><CircleIcon className='active' sx={{fontSize:12, }} /></Tooltip></center></td></tr>
{formState.display === `${number.id}myServerslistk4kd` ? <tr><td colSpan={5}>
<section><ServerMgt title={number.province} menu={props.menu} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} productFeatures={props.productFeatures} /></section>
</td></tr> : null}</tbody>);}

return (
  <section className='mb-5'><PageTitle title='My Account' />
  <section className='d-flex'>
  <section className='sideBar sideBarBg col-lg-3'>
  <List component="nav" aria-label="secondary mailbox folder">
  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
  <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel3bh-content"
  id="panel3bh-header"
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
  >
  <Typography className='fw-bold px-3' sx={{ width: '100%', fontSize:15, }}>
  <ManageAccountsIcon sx={{ mr: 1, }} />Account Settings
  </Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
  >{myAccountSettings}</AccordionDetails></Accordion>
  
  
  
  {curUser && curUser.typeId == 0 ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
  <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel1bh-content"
  id="panel1bh-header"
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
  >
  <Typography className='fw-bold px-3' sx={{ width: '100%', fontSize:15, }}>
  <AdminPanelSettingsIcon sx={{ mr: 1, }} />Admin Tasks
  </Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
  >
  {adminMenu}
  </AccordionDetails>
  </Accordion> : null}
  
  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
  <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel2bh-content"
  id="panel2bh-header"
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
  ><Typography className='fw-bold px-3' sx={{ width: '100%', fontSize:15, }}>
  <Inventory2Icon sx={{ mr: 1, }} />My Products &amp; Services 
  </Typography>
  </AccordionSummary>
  <AccordionDetails
  sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
  >{myProducts}
  
  
  
  <Link onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/143/Tickets`, }}  state={{ pageTitle : 'Tickets' }} className='sideMenu'><ListItem disablePadding dense
  ><ListItemButton selected={selectedIndex === 143} onClick={(event) => { handleListItemClick(event, (143));  resetDisplay(0);}}> {icon(143)} 
  <ListItemText primary='Tickets' /></ListItemButton></ListItem><Divider />
  </Link>
  </AccordionDetails>
  </Accordion>
  
  
  
  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
  <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel4bh-content"
  id="panel4bh-header"
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
  ><Typography className='fw-bold px-3' sx={{ width: '100%', fontSize:15, }}>
  <MoneyIcon sx={{ mr: 1, }} />Billing 
  </Typography>
  </AccordionSummary>
  <AccordionDetails
  sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
  >{myBilling}</AccordionDetails>
</Accordion>
</List>
</section>
<section className='flex-fill flex-grow-1 pt-1 px-3'>mode {mode} itemid {itemId} id {id} tbl {tbl}
{mode == 'editdata' || mode == 'editfield' ? <>
<Typography className='innerPageTitle p-3 fw-bold'>Edit </Typography>
{id}
<section>
<TicketReply id={itemId} />
</section>
{buildForm}
</> : <>

<section className='innerPageTitle p-3 fw-bold'>
<span className="d-flex align-items-center">
<span className="">{typeof state == 'undefined' || state == null ? null : typeof state.pageTitle == 'undefined' || state.pageTitle == null ? null : state.pageTitle}</span>{parseInt(curUser.typeId) !== 0 && parseInt(itemId) === 143 ? 
<span className="flex-fill d-flex flex-row-reverse align-items-center">
<Link onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/138/143/Support Tickets`, }}  state={{ pageTitle : 'Tickets' }} className='sideMenu'><ListItem disablePadding dense
><ListItemButton selected={selectedIndex === 143} onClick={(event) => { handleListItemClick(event, (143));  resetDisplay(0);}}> <AddIcon sx={{ mr: 1, }} />  
<ListItemText primary='Create' /></ListItemButton></ListItem>
</Link></span>
  : null} </span>
</section>






{(curUser && curUser.typeId == 0 && parseInt(itemId) !== 116 && (!([116,4,141,117,118,120,143].includes(parseInt(itemId))))) ? 
<>
<section className=''>
<Box sx={{ width: '100%' }}>
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
<Tab label="Add new" {...a11yProps(1)} /> 
<Tab label="Manage fields" {...a11yProps(2)} /> 
<Tab label="Import data" {...a11yProps(3)} /> 
<Tab label="Export data" {...a11yProps(4)} /> 
</Tabs>
</Box>
</Box>
</section>

<section>
<CustomTabPanel value={value} index={0}> 
{parseInt(itemId) == 138 ? <ListNewTickets /> : <DataTable tbl={itemId} support={true} />}


<ListNewTickets />
<DataTable tbl={itemId} support={true} />

</CustomTabPanel>
<CustomTabPanel value={value} index={1}>
add data {value}

<Form menu={props.menu} type="form" viewid={2} tbl={itemId} formmode={1} />
</CustomTabPanel>
<CustomTabPanel value={value} index={2}>
imports data {value}

<section className='row'>
<section className='col-md-6 mb-3'><DataTable field={true} tbl={itemId} /></section>
<section className='col-md-6 mb-3'><Form menu={props.menu} type="form" viewid={1} tbl={itemId} formmode={1} /></section>
</section>


</CustomTabPanel>
<CustomTabPanel value={value} index={3}>
imports data {value}
</CustomTabPanel>
<CustomTabPanel value={value} index={4}>
export data {value}
</CustomTabPanel>
</section></>
: null}



{[116,117,118,120,143,141].includes(parseInt(itemId))  ? 
<table className='customers'>
{getHeader(itemId)}
{parseInt(itemId) === 117 ? myHosting : null}
{parseInt(itemId) === 116 ? myDomains : null}
{parseInt(itemId) === 118 ? myServers : null}
{parseInt(itemId) === 143 ? myTickets : null}
{parseInt(itemId) === 141 ? myPayments : null}
</table>
: null}
{parseInt(itemId) === 5 ? <Form type="form" viewid={5} tbl={5} formmode={2} /> : null}
{parseInt(itemId) === 4 ? <section><AccountSecurity /></section> : null}
{parseInt(tbl) === 143 ? <section>{tbl}<Form type="form" viewid={2} tbl={138} formmode={1} /></section> : null}
</>}
</section>
</section>
</section>);
}