import {useState} from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import Container from './components/container/container';
import {jwtDecode} from "jwt-decode";
//import Header from './components/header/header';
//import Footer from './components/footer/footer';
import { Country } from './components/context/context';

window.version = "1.0";
var token = "none";
var apiToken = "none";
window.decoded = false;

if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");
window.decoded = jwtDecode(token);
console.log(jwtDecode(token));
} 

const httpLink = createHttpLink({ uri: window.apiServer+'/graphql',});
const authLink = setContext((_, { headers }) => {
  return {
    headers: { ...headers,
      authorization: token ? `Bearer ${token} ${apiToken}` : "",
    }
  }
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true
})


window.cartCookie = document.cookie.split("; ").find((row) => row.startsWith("dredgeCart=")) ?.split("=")[1];
if(window.cartCookie){console.log(window.cartCookie);} else {console.log('no window.cartCookie');}
  



		
		

function App() { 
const [country, setCountry] = useState(130);
return (
<BrowserRouter>
<ApolloProvider client={client}>
<Country.Provider value={country}>
<Container setCountry={setCountry} client={client} />
</Country.Provider>
</ApolloProvider>
</BrowserRouter>
);
}
export default App;