/* eslint-disable */ 
import {useState, useEffect, useRef} from 'react';
import * as RDom from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, gql, useQuery } from '@apollo/client';
import { jwtDecode } from "jwt-decode";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';		 
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import CEditor from '../../components/utility/editor';
import {DeleteData} from '../../components/utility/utility';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import SyncIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';


var token = "none";
var apiToken = "none";
if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");} 


let curUser = null;
//var token = "none";
//var apiToken = "none";
if(sessionStorage.getItem("signIn")){
//token = sessionStorage.getItem("signIn");
//apiToken = sessionStorage.getItem("apiToken");
curUser = jwtDecode(sessionStorage.getItem("signIn"));
} 


function SelectList(props) {
const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(props.id), section:'fieldtype' }, fetchPolicy: "cache-first"});
if (loading) {return "Loading...";}
if (error) {return `Error! ${error.message}`; }
if (data) {
const numbers = data.getSiteList;
const listItems = numbers.map(number =>
<option key={"listfields"+number.id} value={number.string1}>{number.title}</option>);
return ( <><option value=""> --Select-- </option>
{listItems}</>); }}


/*
function SelectDomainExt(props) {
if (props.dropDown) {
const numbers = props.dropDown.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == 152; });
const listItems = numbers.map(number =>
<option key={"topext"+number.id} value={number.string1}>{number.string1}</option>);
return ( <><option value=""> --Select-- </option>
{listItems}</>); }}
{ listid: 2, arg1: '11', arg2: 1834 }
*/
function DropDownOptions(props) {
  const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(props.id), arg1:props.arg1 ? props.arg1 : null, arg2:props.arg2 ? props.arg2 : null }, fetchPolicy: "cache-first"});
if (loading) {return "Loading...";}
if (error) {return `Error! ${error.message}`; }
if (data) {
const numbers = data.getSiteList;
const listItems = numbers.map(number =>
<option key={window.uuidv4()+'DrOptions'+number.id} value={number.inid != 0 ? number.inid : number.itemId}>
{number.string1 != '' && number.string1 != null && number.string1 != 'undefined' && number.string1 != 'null' ? number.string1 : null}
 {number.inid != 0 ? number.inid : number.itemId}</option>);
return ( 
<section className='mb-3'>
<select placeholder={props.placeholder} required={props.required ? true : false} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} ><option value=""> --Select-- </option>{listItems}
</select><label htmlFor={props.name}></label>
</section>); }
}

/*
function Country(props) {
const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(10), arg1:(props.arg1 ? props.arg1 : 'country') }, fetchPolicy: "cache-and-network"});
if (loading) {return "Loading...";}
if (error) {return `Error! ${error.message}`; }
if (data) {
const numbers = data.getSiteList;
const listItems = numbers.map(number =>
<option key={'Country'+number.id} value={number.inid != 0 ? number.inid : number.itemId}> 
{number.string1} {number.inid != 0 ? number.inid : number.itemId}</option>);
return ( <>{props.value}

<div className='form-floating'>
<select placeholder={props.label} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value=""> --Select-- </option>
{listItems}
</select>
<label htmlFor={props.name}>{props.label} {props.required == true ? '*' : null}</label>
</div>

</>); }
}
*/

function OptionList(props){
const { data } = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:String(props.arg1 ? props.arg1 : 11), arg2:parseInt(props.sourceId) }, fetchPolicy: "cache-first"});
let listItems='';
if(data){
const numbersDrop = data.getSiteList;
listItems = numbersDrop.map(number => <option key={`Optist${number.id}`} value={parseInt(props.sourceId) === 152 ? number.string1 : (parseInt(number.inid) !== 0 ? number.inid : number.itemId)}>{number.string1}</option>);
}
return ( 
<select required={props.required} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value=""> {props.placeholder && props.placeholder !== '' ? 
props.placeholder : '--Select--'}</option>
{listItems}
</select>
);

}


const CREATE_MUTATION = gql`mutation AddRecord($input: FormInput) {
  addRecord(input: $input) {
    msg,
    status,
    itemId,
    typeid,
	address
  }
}
`;


export default function GetForm(props){
var frmname = "frm"+props.viewid+Math.random();
let { itemId, mode, id, tbl } = useParams();
var info = "";
var location = RDom.useLocation();
const navigate = useNavigate();

const [formState, setFormState] = useState({
fullname: ((typeof props.fullname !== 'undefined') ? props.fullname : ""),
gender: ((typeof props.gender !== 'undefined') ? parseInt(props.gender) : 0),
username: "",
frmtype:((typeof props.formmode !== 'undefined') ? parseInt(props.formmode) : 1),
formid:(typeof props.viewid !== 'undefined') ? parseInt(props.viewid) : 0,
status:((typeof props.status !== 'undefined') ? parseInt(props.status) : 0),
domain:((typeof props.domain !== 'undefined') ? props.domain : ''),
description:((typeof props.description !== 'undefined') ? (mode === 'editdata' && parseInt(tbl) === 138 ? '' : props.description) : ""),
name:((typeof props.name !== 'undefined') ? props.name : ""),
username:((typeof props.username !== 'undefined') ? props.username : ""),
password:((typeof props.password !== 'undefined') ? props.password : ""),
confirmPassword:"",
address: ((typeof props.address !== 'undefined') ? props.address : ""),
city:((typeof props.city !== 'undefined') ? props.city : window.cartCookie),
province:((typeof props.province !== 'undefined') ? props.province : ""),
zip:((typeof props.zip !== 'undefined') ? props.zip : ""),
country:((typeof props.country !== 'undefined') ? parseInt(props.country) : 0),
itemstatus:((typeof props.status != 'undefined') ? parseInt(props.status) : 0),
rowid:((typeof props.rowid !== 'undefined') ? parseInt(props.rowid) : 0),
tbl:((typeof props.tbl !== 'undefined') ? parseInt(props.tbl) : 0),
phone:((typeof props.phone !== 'undefined') ? props.phone : ""),
title:((typeof props.title !== 'undefined') ? props.title : ""),
id:((typeof props.id !== 'undefined') ? parseInt(props.id) : 0),
planid:((typeof props.planid !== 'undefined') ? parseInt(props.planid) : 0),
uuid:((typeof props.uuid !== 'undefined') ? props.uuid : ""),
total:((typeof props.total !== 'undefined') ? parseInt(props.total) : 0),
filerepos: [],
dropdownSource: false,
planstate:1,
switchState:0,
hideForm:false,
string1:((typeof props.string1 !== 'undefined') ? props.string1 : ""),
string2:((typeof props.string2 !== 'undefined') ? props.string2 : ""),
string3:((typeof props.string3 !== 'undefined') ? props.string3 : ""),
string4:((typeof props.string4 !== 'undefined') ? props.string4 : ""),
string5:((typeof props.string5 !== 'undefined') ? props.string5 : ""),
string6:((typeof props.string6 !== 'undefined') ? props.string6 : ""),
string7:((typeof props.string7 !== 'undefined') ? props.string7 : ""),
string8:((typeof props.string8 !== 'undefined') ? props.string8 : ""),
string9:((typeof props.string9 !== 'undefined') ? props.string9 : ""),
string10:((typeof props.string10 !== 'undefined') ? props.string10 : ""),
string11:((typeof props.string11 !== 'undefined') ? props.string11 : ""),
string12:((typeof props.string12 !== 'undefined') ? props.string12 : ""),
string13:((typeof props.string13 !== 'undefined') ? props.string13 : ""),
string14:((typeof props.string14 !== 'undefined') ? props.string14 : ""),
string15:((typeof props.string15 !== 'undefined') ? props.string15 : ""),
string16:((typeof props.string16 !== 'undefined') ? props.string16 : ""),
string17:((typeof props.string17 !== 'undefined') ? props.string17 : ""),
string18:((typeof props.string18 !== 'undefined') ? props.string18 : ""),
string19:((typeof props.string19 !== 'undefined') ? props.string19 : ""),
string20:((typeof props.string20 !== 'undefined') ? props.string20 : ""),
string21:((typeof props.string21 !== 'undefined') ? props.string21 : ""),
string22:((typeof props.string22 !== 'undefined') ? props.string22 : ""),
string23:((typeof props.string23 !== 'undefined') ? props.string23 : ""),
string24:((typeof props.string24 !== 'undefined') ? props.string24 : ""),
string25:((typeof props.string25 !== 'undefined') ? props.string25 : ""),
string26:((typeof props.string26 !== 'undefined') ? props.string26 : ""),
string27:((typeof props.string27 !== 'undefined') ? props.string27 : ""),
});

const [createRecord, { error, data, loading }] = useMutation(CREATE_MUTATION, {
variables: 
{ input: {
fullname: formState.fullname,
gender: parseInt(formState.gender),
username: formState.username,
frmtype:parseInt(formState.frmtype),
formid:parseInt(formState.formid),
status: parseInt(formState.status),
description: formState.description,
password:formState.password, 
confirmPassword:formState.confirmPassword,
address: formState.address,
city: formState.city,
province: formState.province,
//name: formState.name,
zip: formState.zip,
phone: formState.phone,
title: formState.title,
itemstatus: parseInt(formState.itemstatus),
id: parseInt(formState.id),
country: parseInt(formState.country),
tbl: formState.tbl,
rowid: parseInt(formState.rowid),    
total: parseInt(formState.total),    
planid: parseInt(formState.planid),    
domain: formState.domain,
uuid: (sessionStorage.getItem("apiToken") !== 'undefined') ? sessionStorage.getItem("apiToken") : '',
planstate:formState.planstate,
string1:formState.string1,
string2:formState.string2,
string3:formState.string3,
string4:formState.string4,
string5:formState.string5,
string6:formState.string6,
string7:formState.string7,
string8:formState.string8,
string9:formState.string9,
string10:formState.string10,
string11:formState.stringe11,
string12:formState.string12,
string13:formState.string13,
string14:formState.string14,
string15:formState.string15,
string16:formState.string16,
string17:formState.string17,
string18:formState.string18,
string19:formState.string19,
string20:formState.string20,
string21:formState.string21,
string22:formState.string22,
string23:formState.string23,
string24:formState.string24,
string25:formState.string25,
string26:formState.string26,
string27:formState.string27,
    } },
        fetchPolicy: "no-cache"
    });

/*
useEffect(() => {
setFormState({ ...formState, 
string1: props.string1,
string2: props.string2,
string3: props.string3,
string4: props.string4,
string5: props.string5,
string6: props.string6,
string7: props.string7,
string8: props.string8,
string9: props.string9,
string10: props.string10,
string11: props.string11,
string12: props.string12,
string13: props.string13,
string14: props.string14,
string15: props.string15,
string16: props.string16,
string17: props.string17,
string18: props.string18,
string19: props.string19,
string20: props.string20,
string21: props.string21,
string22: props.string22,
string23: props.string23,
string24: props.string24,
string25: props.string25,
string26: props.string26,
string27: props.string27,
description: props.description,
});
}, [props.string1]);
*/

const [passwordShow, setpasswordShow] = useState("password");
let infoStatus = -1;  
     let infoMsg = ""; 
     let infoFormid = 0 ;
     let infoTbl = 0;        
    let initialRefRow=useRef(true);
    useEffect(() => { 
    if(initialRefRow.current){ initialRefRow.current = false; }
    else{  setFormState({ ...formState, rowid: props.rowid, address: props.address });
      }}, [props.rowid]); // Only re-run the effect if props.rowid changes
  

const prcRecord = async (x) => { 
  if(document.getElementById("prcRec"+x)){document.getElementById("prcRec"+x).innerHTML = `<div class="alert alert-info d-flex" role="alert">
  <span class="spinner-border text-success icon justify-content-center align-self-center"></span>  <span class='mx-3 justify-content-center align-self-center'>Processing, please wait</span></div>`;}
const online = await window.checkOnlineStatus();
if (online) { 
  if(document.getElementById("prcRec"+x)){document.getElementById("prcRec"+x).innerHTML = "";}
  createRecord(); } else { if(document.getElementById("prcRec"+x)){document.getElementById("prcRec"+x).innerHTML = `<div class="alert alert-danger" role="alert">Error! Please check your internet connection</div>`;}
}}
function hideElem(){ if(formState.hideForm === false){setFormState({ ...formState, hideForm:true }); }}

function requireField(){ setFormState({ ...formState, itemstatus: (parseInt(formState.itemstatus) !== 1 ? 1 : 0) }); } 

function enableField(){ setFormState({ ...formState, planid: (parseInt(formState.planid) !== 1 ? 1 : 0) }); } 

if (loading){ info = <div className="alert alert-info d-flex" role="alert">
<span className="spinner-border text-success icon justify-content-center align-self-center"></span>  <span className='mx-3 justify-content-center align-self-center'>Processing, please wait……………</span></div>; }
if (error) {info = <div className="alert alert-danger" role="alert">{error.message}</div>};
if (data) { 
const numbers = data.addRecord; 
infoMsg = numbers.msg; 
infoStatus = numbers.status; 
infoFormid = numbers.itemId; 
infoTbl = numbers.typeid;
info = <center>
<section className="spinner-border text-success"></section> 
<section className="iniPage">Loading, please wait ………….</section> 
</center>;	

if(infoStatus == 1){
info = <div className="alert alert-success" role="alert">Sign in successful, redirecting</div>;}

if(infoStatus == 5){ 
navigate('/logout');
}
if(infoStatus === 2){ 
sessionStorage.setItem("signIn", infoMsg);
sessionStorage.setItem("apiToken", apiToken);
sessionStorage.setItem("decoded", JSON.stringify(jwtDecode(infoMsg)));
window.location.href= "/";
 }
if(parseInt(infoStatus) === 0){
   info =<div className="alert alert-danger" role="alert">{infoMsg} </div>; }
if(parseInt(infoStatus) === 1){ info =<div className="alert alert-success" role="alert"> {infoMsg}  </div>; }}

function ckEditorChange(data,name){
  setFormState({
    ...formState,
    [name]: data
  });
}

function searchDomain(){
if(formState.title != '' && formState.city != null && formState.title != null  && formState.city != ''){
navigate('/dsearch/'+formState.title+''+formState.city+'/'+formState.city);
}}

async function fileUpload(e){
let userId = curUser != null ? curUser.typeId : '-none-';  

if(e.target.files.length > 0){
let newName = window.uuidv4()+'-'+userId+'-'+e.target.files[0].name;
setFormState({
...formState,
fullname: newName,
zip: newName,
province: newName,
});

let formData = new FormData();
formData.append("newname", newName);
formData.append("formid", props.display);
formData.append('myFile', e.target.files[0]);

fetch(window.apiUpload, {
method: "POST",
// Set the FormData instance as the request body
body: formData,
headers: {
"Authorization": `Bearer ${token} ${apiToken}`,
},});
}}

let numbersInput;
function handleChange(e){
  let value = e.target.value;
  if(e.target.type === 'checkbox'){ value = e.target.checked; }
  else if(e.target.type === 'radio'){ value = e.target.value; }
  else if(e.target.type === 'selected'){ value = e.target.selected.value; }  
  const name = e.target.name;
  setFormState({
    ...formState,
    [name]: value
  });


  var radios = document.getElementsByName([name]);

for (var i = 0, length = radios.length; i < length; i++) {
  if (radios[i].checked) { break; }
}
}

function getValue(x){ return formState[x]; }
let prcRecordPrg = (<div id={"prcRec"+props.viewid}></div>);

function showOptionSource(a,b){
setFormState({ ...formState, [a]: b, 
dropdownSource:(b == 'select' ? true : false),
});
}
function passwordVisibility(e) {   
e.preventDefault();    
if(passwordShow === "password"){setpasswordShow("text"); return;}
setpasswordShow("password"); return;  }

const inputs = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(1), arg1:String(props.tbl) }, fetchPolicy: "cache-first"});

const featureInputs = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(25) }, fetchPolicy: "cache-first"});
let listInputs = '';
let listFeatureInputs = '';
//if (inputs.loading) {return ("Loading... "); }
if (inputs.data && featureInputs.data) {  
let dataSource = inputs.data.getSiteList;
if(dataSource.length > 0){
const numbersFeatures = featureInputs.data.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == formState.string5 || parseInt(currItem.planid) == 0; });

listFeatureInputs = numbersFeatures.map((number) => 
<section className='mb-4' key={'listFeatureInputs'+number.id}><Input placeholder={number.title} handleChange={handleChange} value={getValue(number.fullname)} id={number.fullname} name={number.fullname} type={number.province} className="form-control" aria-label={number.title} label={number.title} required={true} itemId={number.inid == 0 ? number.itemId : number.inid} arg1={props.tbl} menu={props.menu} dropDown={number.inid == 0 ? number.itemId : number.inid} />
</section>
);




if(inputs.data){
numbersInput = inputs.data.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) != 0; });
listInputs = numbersInput.map((number) => 
<section className='mb-4' key={'listInputs'+number.id}>
{(number.city === 'select' || number.city === 'image') && parseInt(formState.tbl) === 138 && parseInt(formState.rowid) === -1  ? null : 
<>{number.city === 'textarea' ? <><Typography className='mt-2'>{number.title}</Typography><section>
{curUser !== null && parseInt(curUser.typeId)===0 ? <CEditor curUpdate={'numbersInputckeditor'+number.id} ckEditorChange={ckEditorChange} id={number.address} name={number.address} value={getValue(number.address)} />: <textarea rows={10} maxLength={200} placeholder='Message' id={number.address} onChange={handleChange} name={number.address}  aria-label='description' value={getValue(number.address)} className='form-control' required />}</section></>
: <>

{number.city === 'email' && parseInt(formState.tbl) === 138 && curUser !== null  ? null : <><Input placeholder={number.title} handleFile={fileUpload} handleChange={handleChange} value={getValue(number.address)} sourceId={number.fullname} id={number.address} name={number.address} type={number.city} className="form-control" aria-label={number.title} label={number.title} required={number.broken && number.broken == 1 ? true : false} helpText= {number.description} itemId={number.inid == 0 ? number.itemId : number.inid} arg1={props.tbl} menu={props.menu} tbl={formState.tbl} dropDown={number.inid == 0 ? number.itemId : number.inid} /></>}



</>}
</>}
</section>);

}
}}



function usePrevious(value) { const ref = useRef(); useEffect(() => { ref.current = value; }); return ref.current; } 

const prevSwitchState = usePrevious(formState.switchState);
useEffect(() => { 
if(formState.switchState != 0) { 
if(formState.switchState != prevSwitchState){createRecord();}}}, [formState.switchState]);



const prevTotal = usePrevious(formState.total);
useEffect(() => { 
if(parseInt(formState.total) !== 0 && parseInt(props.viewid) === 18) { 
if(parseInt(formState.total) !== parseInt(prevTotal)){createRecord();}}}, [formState.total]);

useEffect(() => { 
if(parseInt(formState.total) !== 0 && parseInt(props.viewid) === 18) { 
if((parseInt(formState.total) !== parseInt(prevTotal)) && parseInt(infoStatus) === 1){
  props.updateCart();}}}, [formState.total]);

function handleSwitchChange(e){
  if(e.target.checked && parseInt(formState.itemstatus) != 1){
    setFormState({ ...formState, itemstatus: 1, switchState:1 });
  }
  if(!e.target.checked && parseInt(formState.itemstatus) != 2){
    setFormState({ ...formState, itemstatus: 2, switchState:2 });
  }
}

if(props.type === "form"){
switch(parseInt(props.viewid)) {
case 1: // manage form fields
return (           
<div>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }}>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<section>{info}</section>
<Typography className='fw-bold'>{props.formmode == 1 ? 'Add New' : 'Edit'} Field</Typography>
<section className=''>
<section className='form-floating mt-2 mb-3'>
<Input placeholder="Title" handleChange={handleChange} value={formState.title} name="title" maxLength="150" type="text" className="form-control" aria-label="Title" required={1} />
</section>
<section className='form-floating mb-3'>
<select placeholder='Field Type' required className="form-control" id="city" name="city" value={formState.city} onChange={(e) => {
//setFormState({ ...formState, [e.target.name]: e.target.value });
showOptionSource(e.target.name, e.target.value);
}} >
<SelectList arg1='fieldtype' id={9} /> 
</select>
<label htmlFor={'city'}>Field Type *</label>
</section>


{formState.dropdownSource === true ? 
<DropDownOptions id={2} arg1={11} arg2={1834}  />  : null }
<section className='form-floating mb-3'>
<Input placeholder="Help Text" handleChange={handleChange} value={formState.description} name="description" maxLength="100" type="text" className="form-control" aria-label="Title" />
</section>

<section className='d-flex mb-3'>
<section className='flex-fill px-2'><label htmlFor="itemstatus">Required :</label>
<span className='mx-2 switchSize'>
<Switch name='itemstatus' id='itemstatus' onChange={requireField} checked={parseInt(formState.itemstatus) === 1 ? true : false}  /></span></section>

<section className='flex-fill px-2'><label htmlFor="planid">Enable :</label>
<span className='mx-2 switchSize'>
<Switch name='planid' id='planid' onChange={enableField} checked={parseInt(formState.planid) === 1 ? true : false}  /></span></section>
</section>
</section>

<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>
</form>
</div>
);  
case 2: // manage data
return (<>
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 138 ? hideElem() : null}
<div className="">
<section>{info}</section>
{formState.hideForm === false ?
<><form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />


{mode === 'editdata' && parseInt(formState.tbl) === 138 ? <>{getValue('description')} desc {formState.description}<CEditor curUpdate={'numbersInputckeditoredit'} ckEditorChange={ckEditorChange} id='description' name='description' value={getValue('description')} /></> : null}

{mode !== 'editdata' ? <>{curUser != null && parseInt(curUser.typeId)==0 ? 
<section className="d-flex align-items-center fw-bold pageTitle pb-2 mb-2">
<section className="flex-grow-1 d-flex flex-column"> <div className="flex-fill d-flex flex-row-reverse align-items-center pointer" >{props.viewid != 24 ? <SyncIcon onClick={() => { inputs.refetch()}} /> : null}</div></section></section> : null}
<section className=''>{listInputs}</section> 
{(props.tbl == 133 && formState.frmtype == 2) ? <section className=''>{listFeatureInputs}</section> : null}</> : null}

{mode === 'editdata' && parseInt(formState.tbl) !== 138 ? <>{curUser != null && parseInt(curUser.typeId)==0 ? 
<section className="d-flex align-items-center fw-bold pageTitle pb-2 mb-2">
<section className="flex-grow-1 d-flex flex-column"> <div className="flex-fill d-flex flex-row-reverse align-items-center pointer" >{props.viewid != 24 ? <SyncIcon onClick={() => { inputs.refetch()}} /> : null}</div></section></section> : null}
<section className=''>{listInputs}</section> 
{(props.tbl == 133 && formState.frmtype == 2) ? <section className=''>{listFeatureInputs}</section> : null}</> : null}




<section className='my-3'>
{(inputs.data && inputs.data.getSiteList.length > 0) ? <><span><Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
{props.handleCancel ? <Button onClick={() => { props.handleCancel(1)}} variant="outlined"  color="info mx-2">Cancel</Button> : null}
</span>
</> : null}
</section></form>
</>
: null}
</div>
</>
);

case 28: // add to cart form
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<section className='mt-5 mb-4'>
<span onClick={(e) => { e.preventDefault(); createRecord(); props.updateCart(); }}>
<AddShoppingCartIcon sx={{ marginRight:2, fontSize:35, color:'green' }} />
</span> 
</section></form>
</div>
);
case 29: // delete cart item
{parseInt(infoStatus) === 1 && props.updateCart ? props.updateCart() : null}
return (        
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<span onClick={(e) => { e.preventDefault(); createRecord();  }}>
<Tooltip title="Remove item">
<DeleteIcon sx={{color:'#AE0000'}} /></Tooltip>
</span> 
</form>
);

case 32: // delete data
return (        
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<span onClick={(e) => { e.preventDefault(); createRecord(); props.updateDelete ? props.updateDelete() : null; }}>
<Button variant="outlined"  color="error" startIcon={<DeleteIcon />}>
        Delete
      </Button>
</span> 
</form>
);
case 8: 
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />

<section className='row'>
<section className='col-md-6'>
<Typography className='fw-bold'>Current Fields</Typography>
<section className=''></section>
</section>


<section className='col-md-6'>
<Typography className='fw-bold'>{props.tbl} Add New Field {formState.tbl} secons</Typography>


<section className=''>
<section className='form-floating mt-2 mb-3'>
<Input placeholder="Title" handleChange={handleChange} value={formState.title} name="title" maxLength="150" type="text" className="form-control" aria-label="Title" required={1} />
</section>


<section className='form-floating mb-3'>
<select placeholder='Field Type' required className="form-control" id="city" name="city" value={formState.city} onChange={(e) =>
setFormState({
...formState,
[e.target.name]: e.target.value
})} >
<SelectList arg1='fieldtype' id={9} /> 
</select>
<label htmlFor={'city'}>Field Type *</label>
</section>

<section className='form-floating mb-3'>
<Input placeholder="Help Text" handleChange={handleChange} value={formState.description} name="description" maxLength="100" type="text" className="form-control" aria-label="Title" />
</section>


<section className='d-flex mb-3'>
<section className='flex-fill px-2'><label htmlFor="itemstatus">Required :</label>
<span className='mx-2 switchSize'>
<Switch name='itemstatus' id='itemstatus' onChange={requireField} checked={parseInt(formState.itemstatus) === 1 ? true : false}  /></span></section>

<section className='flex-fill px-2'><label htmlFor="planid">Enable :</label>
<span className='mx-2 switchSize'>
<Switch name='planid' id='planid' onChange={enableField} checked={parseInt(formState.planid) === 1 ? true : false}  /></span></section>
</section>
</section>


<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>
</section>  
</section>  
</form>
</div>

);   
case 33: // change password
return (        
<section>
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-2">
<section className="input-group mb-3">
<span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="password" type={passwordShow} placeholder="Password" value={formState.password} required={true} handleChange={handleChange} />
</section>
</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
<section className="col-sm-6 mb-2">
<section className="input-group mb-3">
<span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="confirmPassword" type={passwordShow} placeholder="Confirm Password" value={formState.confirmPassword} handleChange={handleChange} required={true} />
</section>

</section> 
</section>
<Typography className="alert alert-info" role="alert">
Password must contain a number, special character, uppercase letter, lowercase letter and be 6 to 15 characters long
</Typography>
<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>

</section>
);

case 36: // cancel aCCOUNT
return (        
<section style={{maxWidth:'100%',}}>
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>
<section className="input-group mb-3">
<span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="password" type={passwordShow} placeholder="Password" value={formState.password} required={true} handleChange={handleChange} />
</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
</section>
<section className='d-flex flex-wrap'>
<div className="wrap">


   <Alert variant="filled" severity="warning">
  <p>All products associated with your account will be terminated and any data you have with us will be deleted. </p>
  <p>For legal requirements, we might hold your data for such length of time as may be required by law or stated in our legal documents</p>
     <p>Account cancelled cannot be reactivated, please enter your password to cancel your account</p> 
      </Alert>


</div>
</section>
<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-danger btn-md">Cancel Account</Input>
</section>   
</section>
</form>

</section>
);

case 22: // domain search form
return (        
<>
<section className="d-flex">
<span className="flex-fill px-1">
<input onChange={handleChange} type="text" name="title" maxLength="140" className="form-control" placeholder='Enter a domain name' aria-label="Domain name" value={formState.title} size='40' id='title' required />
</span>
<span className="flex-fill">
<OptionList sourceId={152} placeholder='' required={true} id="city" name="city" value={formState.city} handleChange={handleChange} />
</span>
<span className="flex-fill px-1 pointer"><TravelExploreIcon onClick={() => { searchDomain()}} />
</span>
</section>
</>);  
case 34: // two way authentication
case 51: // auto renewal
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (<><Switch onChange={handleSwitchChange} aria-label={props.label ? props.label : 'Enable 	Two  Authentications'} checked={parseInt(formState.itemstatus) == 1 ? true : false} size="small" /></>);  

case 18: // update quantity
//{(parseInt(infoStatus) === 1 && props.updateCart) ? props.updateCart() : null}

if(parseInt(props.typeid) === 1040){ return (
  <select required id='total' className='form-control' name='total' value={formState.total} onChange={handleChange} >
  <option value={1}>1</option>
  <option value={2}>2</option>
  <option value={3}>3</option>
  </select>);}

if(parseInt(props.typeid) === 1041){ return (
  <select required id='total' className='form-control' name='total' value={formState.total} onChange={handleChange} >
  <option value={1}>1</option>
  <option value={3}>3</option>
  <option value={6}>6</option>
  <option value={12}>12 </option>
  <option value={18}>18 </option>
  <option value={24}>24 </option>
  </select>);}



case 35: // recovery email
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (   
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>{info}</section>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" /> 
<section className="mb-4">
<Input name="username" type="email" placeholder="Enter your recovery email" value={formState.username} helpText='This email will be used to recover your account in case you lose access to the login email account' handleChange={handleChange} required={true} />
</section>
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</form>); 

case 37: // support pin
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (   
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>{info}</section>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" /> 
<section className="mb-4">
<Input name="total" type="number" placeholder="Support PIN" value={formState.total} helpText='The support PIN will be required by a support staff to access your account for technical assistance' handleChange={handleChange} required={true} />
</section>
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</form>);   
case -12: // login
return (        
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); prcRecord(props.viewid); }} >
<main className="bg-white">
<section className="card bg-white">
            {prcRecordPrg}
            {info}
            <article className="card bg-white">
              <section className="card-header frmTitle">
              Sign in	
              </section>
                  <section className="card-body p-4">
                    <section className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
                      <Input name="username" type="email" placeholder="Username" value={formState.username} 
                        handleChange={handleChange} required={true} />
                    </section>
                    <section className="input-group mb-3">
                        <span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
                        <Input name="password" type={passwordShow} placeholder="Password" value={formState.password} 
                        handleChange={handleChange} required={true} />
                    </section>
                    <section className='mb-2 card-title'>
                      <Input name="continueBtn" className="btn btn-success form-control" type="submit">
                        Continue
                      </Input>
                    </section>
                    <section className='mb-2'>
                    <RDom.Link to="/acct/112/password"  className="btn btn-light form-control">
                      Reset Password
                    </RDom.Link>
                    </section>  
                     <section className='mb-2'>
                    <RDom.Link to="/acct/108/register"  className="btn btn-light form-control">
                      Register
                    </RDom.Link>
                    </section>  
                  
                  </section>
              </article>
            </section>
        </main>
		</form>
 ); // end login
      case -1:
      return (        
        <section>
    <section>
    {prcRecordPrg}
          {info}
        </section>
          <form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); prcRecord(props.viewid);  }} >
          <section className="row mx-4">
                <section className="col-sm-6 mb-2">
                <label htmlFor="username">New Password *</label><br />
                <section className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1"><i onClick={passwordVisibility} className="fas fa-eye-slash"></i></span>
                    <Input required="required" name="password" type={passwordShow} placeholder="Password" value={formState.password} 
                    handleChange={handleChange} 
                    title="Password must contain a number, special character, uppercase letter, lowercase letter and be 7 to 15 characters long"   />
                </section>
                </section>
                <input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
                <section className="col-sm-6 mb-2">
                <label htmlFor="username">Confirm New Password *</label><br />
                <section className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1"><i onClick={passwordVisibility} className="fas fa-eye-slash"></i></span>
                    <Input required="required" name="confirmPassword" type={passwordShow} placeholder="Confirm Password" value={formState.confirmPassword} 
                    handleChange={handleChange} />
                </section>
               
                </section> 
              </section>
              <section className="alert alert-info" role="alert">
                Password must contain a number, special character, uppercase letter, lowercase letter and be 6 to 15 characters long
                </section>
              <section className="row mx-4">
                <section className="mb-4">
                    <Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
                  </section>   
              </section>
            </form>
          
        </section>
        );
       //-4 is for user profile container
    
   
   
     
        default:
          return null;
      } 
   }
        return null;
    }
  


function Input(props){
var label = "";
switch(props.type) {
case "submit":
case "button":
return (
<button type={props.type} className={props.className}>{props.children}</button>
);

case "text":
case "email":
case "password":
case "tel":
case "decimal":
case "date":
case "number":
case "url":
case "tel":
case "time":
case "date-time":
case "month":
case "week":
case "password":


return (<Typography className='form-floating'>
<input type={(props.type == 'decimal') ? 'number' : props.type} maxLength={props.maxLength !== 'undefined' ? props.maxLength : '100'} placeholder={props.title !== 'undefined' ? props.title : null} onChange={props.handleChange} name={props.name} id={props.name} className={'form-control '+props.className} value={props.value ? props.value : ''} required={(props.required !== 'undefined' && (parseInt(props.required) == 1 || props.required == true)) ? true : false} onFocus={(props.handleFocus !== 'undefined') ? props.handleFocus : null} min={(props.min !== 'undefined') ? props.min : null} max={(props.max !== 'undefined') ? props.max : null} step='0.01' />
<label htmlFor={props.name}>{(props.placeholder !== 'undefined') ? props.placeholder : null} {(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? '*' : null}</label><span className=''></span><small className="form-text text-muted">{(props.helpText !== 'undefined') ? props.helpText : null}</small></Typography>
); 
case "select":
return (<>sourceId={props.sourceId}
<OptionList sourceId={props.sourceId} placeholder={props.placeholder} required={props.required} id={props.name} name={props.name} value={props.value} handleChange={props.handleChange} /></>
);

case "image":
case "file":
return (
<div>
  <label htmlFor={props.name}>{(props.placeholder !== 'undefined' ) ? props.placeholder : 'Select File'}</label><br />
  <input id={props.name} name={props.name} onChange={props.handleFile} type="file" />
</div>
); 

case "textarea":
return (<>
<span className='form-floating'>
<textarea style={{width:'100%',}}
rows={10}
value={props.value}
maxLength={props.maxLength !== 'undefined' ? props.maxLength : '1000'}
placeholder={props.placeholder !== 'undefined' ? `${props.placeholder}${(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? '*' : null}` : null} 
aria-label={props.title}  
required={(props.required !== 'undefined' || (parseInt(props.required) === 1 || props.required === true)) ? true : false}
name={props.name} 
id={props.name}
onChange={props.handleChange}
/></span>
</>
); 

default:
return (null); 
} 
}



export {Input}