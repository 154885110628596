import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import DomainBG from '../../assets/search.gif';
import ResWeb from '../../assets/resweb.gif';
import AVA from '../../assets/ava.png';

import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';
import Form from '../../components/form/form';
import { ProductFeatures, AddToCart} from '../../components/utility/utility';


export default function Search(props) { 
let { id, ext } = useParams();
const [formState, setFormState] = useState({
display: 0,
validDomain: 0,
resultBack: 0,
resultStatus: 0,
});
let itemPrice='';
let addToCart = '';

const dropDownOptions = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'11', arg2:152 }, fetchPolicy: "cache-and-network" }); 
const searchOptions = useQuery(DEF_SITE_LIST, {variables: { listid: 22, arg1:id }, fetchPolicy: "cache-and-network" }); 


useEffect(() => {
if(formState.display != 0){setFormState({ ...formState, display: 0 });}
if(formState.validDomain != 0) { setFormState({ ...formState, validDomain: 0 }); }
if(formState.resultBack != 0) { setFormState({ ...formState, resultBack: 0 }); }
if(formState.resultStatus != 0) { setFormState({ ...formState, resultStatus: 0 }); }
if(!domainValidate(id)){
if(formState.validDomain != 1) { setFormState({ ...formState, validDomain: 1 }); }} 
else {if(formState.display != 2) { setFormState({ ...formState, display: 2 }); }}
return () => {};
}, [id]);


function showResult(dname) {
if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(dname)) {
if(formState.display != 1){setFormState({ ...formState, display: 1 });}
if(formState.validDomain != 1) { setFormState({ ...formState, validDomain: 1 }); }
}
return true;}

function domainValidate(dname) {
if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(dname)) {
return true; } return false; }


//if(id){ domainValidate(id); } 

let error = '';
let result = '';
let listDPrices='';

if(searchOptions.error && domainValidate(id)){ 
if(formState.resultBack == 0){setFormState({ ...formState, resultBack: 1 });}
error = (<section className="alert alert-warning offline" role="alert">Connection Error! {searchOptions.error.message}</section>);} 
if(searchOptions.data && domainValidate(id)){ //if(formState.resultBack != 1){
if(formState.resultBack == 0){setFormState({ ...formState, resultBack: 1 });}
if(parseInt(searchOptions.data.getSiteList[0].id) == -11){
if(formState.resultStatus != 1){setFormState({ ...formState, resultStatus: 1 });}
}
if(searchOptions.data.getSiteList[0].id == null || parseInt(searchOptions.data.getSiteList[0].id) != -11){
if(formState.resultStatus != 0){setFormState({ ...formState, resultStatus: 0 });}
}}

if(dropDownOptions.data && dropDownOptions.data.getSiteList.length > 0){
const getItem = dropDownOptions.data.getSiteList.filter(function(currItem) { return currItem.string1 == ext; });
addToCart=<><AddToCart cart rowid={getItem[0].inid == 0 ? getItem[0].itemId : getItem[0].inid} groupid={116} type='domain' prdname={id} price={getItem[0].string3} currency={props.currency} itemId={ext} updateCart={props.updateCart} uom={1040} /></>;
const numbersDPrices = dropDownOptions.data.getSiteList;
listDPrices = numbersDPrices.map(number =>
<section className='col-6 col-md-6 col-lg-4 text-center mb-5 featureList pb-2' key={'itemPrice'+number.id}>{number.string1}<br /><AddToCart rowid={number.inid == 0 ? number.itemId : number.inid} groupid={116} type='domain' uom={1040} price={number.string3} currency={props.currency} itemId={ext} /></section> 
);


return (
<section className='mt-3 mb-4 defMrg'>
{formState.resultBack == 0 ? <section className='d-flex justify-content-center'>
{formState.display == 2 ? <section>
<h3 className='m-4 fw-bold text-center'>We are getting you ready for something big</h3><section className='m-3 text-center'><img src={DomainBG} className='img-fluid' loading='lazy' /></section>
<h5 className='m-5 text-center'>Looking up domain name: {id}</h5>
</section> : null}

{formState.display == 0 ? <section>
{formState.validDomain == 0 ? <Typography variant='h5' className='mb-5'>Please enter a domain name, choose extension and click search </Typography> : null}
{formState.validDomain == 1 ? 
<section className="alert alert-warning fw-bold" role="alert">Invalid domain name</section> : null}
<Typography variant='h5' className='mb-5 text-center'>Please enter another domain name </Typography>
<section className='m-3 text-center'><img src={ResWeb} className='img-fluid' loading='lazy' /></section>
<section className=''><Form type="form" dropDown={dropDownOptions.data} viewid={22} formmode={1} /></section>
</section> : null}
</section> : null}






{formState.resultBack == 1 ? <>
{formState.resultStatus == 0 ? <>
<h3 className="mb-5 alert alert-warning fw-bold p-3 fw-bold" role="alert">{id}: Is not available</h3>
<Form type="form" dropDown={dropDownOptions.data} viewid={22} formmode={1} />
</> : null}

{formState.resultStatus == 1 ? <>
<section className='row'>
<section className='col-md-6 px-5 mb-4'>
<section className='mb-4 text-center'><img src={AVA} className='img-fluid' loading='lazy' /></section>
<h3 className="mb-5 alert alert-success text-center fw-bold p-3 fw-bold" role="alert">{id}: Is Available</h3>
<section className='m-3 text-center'>{addToCart}</section>
<h5 className='mb-4 mt-5 text-center text-blue fw-bold'>Domain Pricing</h5>
<section className='d-flex justify-content-center'>
<section className='row mb-4'>
{listDPrices}
</section>
</section>

</section>
<section className='col-md-6 px-3 mb-4'>
<h4 className='mb-4 fw-bold text-center'>What you get with every domain purchase</h4>
<section className=''><ProductFeatures gId={116} free={true} /></section>
</section>
</section>
</> : null}


</> : null}
</section>
);
}
}