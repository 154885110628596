//import { useState, useRef, useEffect } from 'react';
//import { Link, useParams, Routes, Route } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LogIn from '../../components/account/account';
import Password from '../../components/account/password';
import Register from '../../components/account/signup';
import Myacct from '../../components/myacct/myacct';
import LoginBG from '../../assets/login.png';
import ResetBG from '../../assets/reset.png';
import {CarTTable, NotFound, Restricted} from '../../components/utility/utility';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { PopoverPaper } from '@mui/material';

export default function App(props) { 
let { id } = useParams();
let text = null;
text = parseInt(id) && parseInt(id) == 108 ?  <Card>
<CardActionArea>
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Register now to get full access to all our services
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Personalized Features:</strong> Enjoy tailored recommendations and settings that fit your unique needs and preferences
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Enhanced Security:</strong> Benefit from robust security measures that keep your data safe and secure
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Exclusive Access:</strong> Get early or exclusive access to new tools, features, and content that are only available to our registered users
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Seamless Integration:</strong> Effortlessly integrate with other tools and services you use, creating a more cohesive and efficient workflow
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Priority Support:</strong> Receive dedicated support and faster response times from our customer service team, ensuring any issues are resolved quickly
</Typography>
</CardContent>
</CardActionArea>
</Card> : parseInt(id) && parseInt(id) == 110 ?  <Card>
<CardActionArea>
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Expand Your Offerings by Reselling Our Web Service</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
Elevate your business by partnering with us to resell our cutting-edge web services. Here&acute;s why reselling our solutions is a smart move for your company
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Enhanced Security:</strong> Benefit from robust security measures that keep your data safe and secure
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Diverse Portfolio:</strong> Access a wide range of high-quality web services that cater to various customer needs, from cloud storage and hosting to advanced analytics and cybersecurity
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Increased Revenue Streams:</strong> Boost your revenue by offering our services to your clients, with attractive margins and flexible pricing options.
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Branded Solutions:</strong> Customize and brand our services to align with your company&acute;s identity, providing a seamless experience for your customers
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Ongoing Support:</strong> Benefit from our comprehensive support, including training and technical assistance, to ensure you and your clients receive exceptional service.
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>
<strong>Scalability:</strong> Easily scale your offerings as your business grows, with solutions designed to adapt to various sizes and types of businesses.
</Typography>
</CardContent>
</CardActionArea>
</Card> : parseInt(id) && parseInt(id) == 109 ? <img src={LoginBG} loading='lazy' className="img-fluid" /> : null;

switch(id ? String(id) : 0) {
/*
case 109: 
return (<section className='defMrg mx-5 px-5'><section className='row'>
<section className='col-md-6 mb-4 px-4'><LogIn /></section>
<section className='col-md-6 mb-4 px-4'>{text}</section>
</section></section>);
*/
case '109': 
return (<section className='defMrg mb-5 d-flex align-items-center justify-content-center'>
<section className='frmCon'><LogIn /></section>
</section>);
case '108': 
case '110': 
return (<section className='defMrg mx-5 px-5'><section className='row'>
<section className='col-md-6 mb-4 px-4'><Register /></section>
<section className='col-md-6 mb-4 px-4'>{text}</section>
</section></section>);
case '112': 
return (<section className='defMrg mx-5 px-5'><section className='row'>
<section className='col-md-6 mb-4 px-4'><Password /></section>
<section className='col-md-6 mb-4 px-4'><img src={ResetBG} loading='lazy' className="img-fluid" /></section>
</section></section>);
case '111': 
return (<section className='defMrg'><CarTTable updateCart={props.updateCart} cartItems={props.cartItems} currency={props.currency} /></section>);
case '107': 
case '114': 
case '143': 
return (<section className='defMrg'>
{(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? <Restricted /> : <Myacct menu={props.menu} products={props.products} ownData={props.ownData} productFeatures={props.productFeatures} />}</section>);
default:
return (<section className='defMrg'><NotFound /></section>);
}}