import { useParams } from 'react-router-dom';
import {PageTitle, LoadingSK, RigthPromo, NotFound} from '../../components/utility/utility';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';
import Form from '../../components/form/form';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';

export default function Content(props) { 
const {gid, title, id, menuid} = useParams();
const details = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:String(gid), arg2: parseInt(id) , arg3:'getcontent'}, fetchPolicy: "cache-and-network" }); 
let content = <NotFound />;
let abuse = '';

if(details.error){content = <section className="alert alert-warning offline" role="alert">Connection Error! {details.error.message}</section>;} 
if(details.loading){content = <section className='mt-3 mb-4 defMrg'><LoadingSK /></section>;} 
if(details.data){
if(details.data.getSiteList.length > 0){
content = <section className='mt-3 mb-4 defMrg'>
<section className='mb-2'><PageTitle title={title} /></section>
<section dangerouslySetInnerHTML={{__html: details.data.getSiteList[0].string3}}></section></section>;} 
}


let teaser = '';
if(parseInt(id) === 140){teaser = (<Card className='mb-5'>
<CardActionArea>
<CardContent>
<Typography gutterBottom variant="h5" component="div" className='fw-bold'>Need assistance with our products or have questions before making a purchase?</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Our Sales Support team is here to help!</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}> Fill out the form, and we’ll provide you with the information and guidance you need to make the best choice</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>We look forward to assisting you!</Typography>

</CardContent>
</CardActionArea>
</Card>);}



if(parseInt(id) === 136){teaser = (<Card className='mb-5'>
    <CardActionArea>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div" className='fw-bold'>Have questions, feedback, or need assistance? </Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>We’re here to help! Fill out the form, and a member of our team will get back to you shortly.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Whether you're inquiring about our services, need support, or just want to connect
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>We look forward to hearing from you!
    </Typography>
    
    </CardContent>
    </CardActionArea>
    </Card>);}

    
if(parseInt(id) === 139){teaser = (<Card className='mb-5'>
    <CardActionArea>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div" className='fw-bold'>Feedback & Share Your Ideas </Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Your insights and ideas help us improve! We’d love to hear your feedback on our products and services, as well as any suggestions you have for new features or improvements.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Fill out the form to share your thoughts, and together, we can make our offerings even better.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Thank you for helping us grow!
    </Typography>
    
    </CardContent>
    </CardActionArea>
    </Card>);}
    
    
    
    if(parseInt(id) === 138){teaser = (<Card className='mb-5'>
    <CardActionArea>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div" className='fw-bold'>Help us maintain a safe and respectful environment</Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}> If you've encountered inappropriate behavior, content, or misuse of our platform, please fill out the form to report it.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Our team will review your submission and take appropriate action to address the issue promptly. 
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Thank you for helping us protect our system
    </Typography>
    
    </CardContent>
    </CardActionArea>
    </Card>);}
    
    
    let processContentld = [136, 138, 139, 140]; // support
    if(processContentld.includes(parseInt(id))){ 
    content = (<section className='mt-3 mb-4 defMrg'>
    <section className=''><PageTitle title={title} /></section>
    <section className='px-4 d-block d-lg-none'>{teaser}</section>
    <section className='d-flex'>
    <section className='flex-grow-1 px-4'>
    <section className='mt-5 mb-5'><Form type="form" viewid={2} tbl={138} formmode={1} rowid={-1} /></section>
    </section>
    <section className='px-4 d-none d-lg-block flex-fill' style={{width:'40%'}}>
    {teaser}
    </section>
    
    </section>
    </section>);
    }


return (<section className=''>{content}</section>);}
