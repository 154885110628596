/* eslint-disable */ 
import React, { useState, useEffect, useContext } from 'react';
import NFImg from '../../assets/notfound.png';
import LogInBG from '../../assets/loginbg.png';
import { useParams, useNavigate } from 'react-router-dom';
import { Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
//import Switch from '@mui/material/Switch';
//import Paper from '@mui/material/Paper';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { jwtDecode } from "jwt-decode";
import Skeleton from '@mui/material/Skeleton';
import { Country } from '../../components/context/context';
import LogIn from '../../components/account/account';
import Payment from '../../components/account/payment';
import PaySuccess from '../../assets/paysuccess.png';
//import SettingsIcon from '@mui/icons-material/Settings';


import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';


import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import Form, {Input} from '../../components/form/form';
import Slide from '@mui/material/Slide';
import SyncIcon from '@mui/icons-material/Sync';


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
//import SettingsIcon from '@mui/icons-material/Settings';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

let curUser = null;
//var token = "none";
//var apiToken = "none";
if(sessionStorage.getItem("signIn")){
//token = sessionStorage.getItem("signIn");
//apiToken = sessionStorage.getItem("apiToken");
curUser = jwtDecode(sessionStorage.getItem("signIn"));
} 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GetUOM(props) {
const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:props.arg1 ? String(props.arg1) : 'uom', arg2:props.arg2 ? parseInt(props.arg2) : null }, fetchPolicy: "cache-first"});
if (loading) {return <LoadingSK />;}
if (error) {return `Error! ${error.message}`; }
if (data && data.getSiteList.length > 0) {  return ( data.getSiteList[0].string1); }}

function DataTable(props) { 
let { itemId, id } = useParams();
//let load = <LoadingSK />;
const { loading, error, data, refetch } = useQuery(DEF_SITE_LIST, { variables: { listid: 1, arg1:String(props.tbl) , arg2:parseInt(id)}, fetchPolicy: "cache-and-network"});
//if (loading) {return <LoadingSK />;}
if (error) {return `Error! ${error.message}`; }  

//const getTicketCov = useQuery(DEF_SITE_LIST, { variables: { listid: 1, arg1:String(props.tbl) , arg2:parseInt(id), arg3:(props.support ? 'support' : '')}, fetchPolicy: "cache-and-network"});


const getData = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:String(itemId) }, fetchPolicy: "cache-and-network" }); 

const updateDisplay = props.updateDisplay;
const tbl = props.tbl;

let headerForManage;
let dataForManage;
let headerForList=[];
let dataForList=[];
let useKeys1=[];
let dataRow=[];

if(data){
const numbersCol = data.getSiteList;
headerForManage = ( 
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>Title</th>
<th>Description</th>
</tr>
</thead>);
  
dataForManage= numbersCol.map(number =>  
<tr className="p-2 pointer" key={`dataForManage${number.id}`}>
<td className='editCol px-1 pointer' onClick={(e) => { window.modifyState(`/acct/114/editfield/${number.inid == 0 ? number.itemId : number.inid}/${number.tbl}`,'') }}>&#9998;</td><td>{number.title}</td><td>{number.description}</td></tr> );
for (let i = 0; i < data.getSiteList.length; i++) {
let element= {address:data.getSiteList[i].address, title:data.getSiteList[i].title};
headerForList.push(element); }}

for (let i = 0; i < headerForList.length; i++) {   useKeys1.push(headerForList[i].address);}

if(getData.data){
//let genDataList='';

for (let i = 0; i < getData.data.getSiteList.length; i++) { 
let element = {};


for (const [key, value] of Object.entries(getData.data.getSiteList[i])) {
if(useKeys1.includes(key)){ element[key] = value; }}
dataForList.push(element); }

if(getData.data.getSiteList.length > 0){
for (let i = 0; i < getData.data.getSiteList.length; i++) { 
let tmpRow='' ;
if(useKeys1.length > 0){ 
for (let a = 0; a < useKeys1.length; a++) {
let tmpAdd = useKeys1[a];
tmpRow=`${tmpRow}<td>${removeHTMLTags(getData.data.getSiteList[i][tmpAdd]).substring(0,50)} </td>`
}} 
dataRow.push(`<tr><td class='editCol pointer' onClick="window.modifyState('/acct/114/editdata/${getData.data.getSiteList[i].inid == 0 ? getData.data.getSiteList[i].itemId : getData.data.getSiteList[i].inid}/${getData.data.getSiteList[i].tbl}','')">&#9998;</td>${tmpRow}</tr>`); 
}} 

for (let i = 0; i < getData.data.getSiteList.length; i++) {
let tmpData = {};
for (let a = 0; a < headerForList.length; a++) {
let objRef = headerForList[a];
}  
dataForList.push(tmpData); 
}}

let curHeader;
let curData='';
let genListHeader='';
let genListData='';
if(props.field != 'undefined' && props.field == true){
curHeader =headerForManage; curData= <tbody>{dataForManage}</tbody>; } else {


if(headerForList.length > 0){
for (let i = 0; i < headerForList.length; i++) {
//if (i === 3) break;
genListHeader += `<th>${headerForList[i].title}</th>`;}}
genListHeader= `<th class='editCol'>&nbsp;</th>${genListHeader}`;
curHeader = (<thead><tr dangerouslySetInnerHTML={{__html: genListHeader}}></tr></thead>);
curData=(<tbody dangerouslySetInnerHTML={{__html: dataRow}}></tbody>);
//curData=dataForList;
}
  
function removeHTMLTags(htmlString) {
// Create a new DOMParser instance
const parser = new DOMParser();
// Parse the HTML string
const doc = parser.parseFromString(htmlString, 'text/html');
// Extract text content
const textContent = doc.body.textContent || "";
// Trim whitespace
return textContent.trim();
}
  
  
  return <> 
{itemId} {id}
<section className='tableCon'>
<table className="customers">
{curHeader}
{curData}
</table>
</section>
</>;
}


function NotFound(props) {  
return ( 
<section className='defMrg8'>
<section className='mx-5 m-2 mb-4 px-3'>
<section className='mt-5 mb-5 notfound pt-5 pb-5'>
<section className='d-block d-lg-none p-5 text-center'><img src={NFImg} className='img-fluid' /></section>


<section className="d-flex justify-content-center pt-2 p-2">
<section className='d-flex flex-column justify-content-center'>
<section className='mx-2 p-1 px-4 p-3'>
<h3>Something&apos; s wrong here ...</h3>
<p>We can&apos;t find the page you&apos;re looking for. Head back home</p>
<p>
<span className='m-1 mt-1 mb-1'>
<Button sx={{ fontSize: 11, m:1, color: '#000000', backgroundColor:'#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" size="small"><Link onClick={ () => window.topTop() } to={{ pathname: "/"}} className='btnLinkBlack'>Home</Link></Button></span>
</p>
</section>
</section>


<section>
<section className="flex-grow-1 d-flex flex-column d-none d-lg-block justify-content-start align-items-start align-self-start">
<img src={NFImg} className='img-fluid' />
</section>
</section>
</section>
</section>
</section> 
</section> 
);
}


function SocialConnect(props) { 
return (<>
<Tooltip title="Face book" arrow={true}><span className='mx-2 pointer'><FacebookIcon sx={{ fontSize: 20, }} /></span></Tooltip>
<Tooltip title="LinkedIn" arrow={true}><Link to='https://www.linkedin.com/in/
dredgeresources' target='_blank' rel='noopener' className='mx-2 pointer' style={{color: '#ffffff', textDecoration: 'none'}}><LinkedInIcon sx={{ fontSize: 20, }} /></Link></Tooltip>
<Tooltip title="X" arrow={true}><Link to='https://x.com/dredgetech' target='_blank' rel='noopener' className='mx-2 pointer'  style={{color: '#ffffff', textDecoration: 'none'}}><XIcon sx={{ fontSize: 20, }} /></Link></Tooltip>
</>);}




function SideMenu() {
const [selectedIndex, setSelectedIndex] = React.useState(1);
const handleListItemClick = (event, index) => { setSelectedIndex(index); };
const sideMenu = useQuery(DEF_SITE_LIST, { variables: { listid: 7 }, fetchPolicy: "cache-and-network" });
let listItems = '';
if(sideMenu.data){
let numbers = sideMenu.data.getSiteList;  
listItems = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<section key={'listItems'+number.id}>
<Typography><Link to={'/'+(number.inid == 0 ? number.itemId : number.inid)+'/'+(number.title)} state={{ pageTitle : number.title, addnew:number.addnew, moduleid:(number.inid == 0 ? number.itemId : number.inid), }} className="sideMenu d-block pb-1 mt-2">{number.title}</Link>
</Typography>
<Divider />
</section> 
);}
return (<section className='mb-3 mt-2'>{listItems}</section>);
}



function DropMenu(props){
const menuList = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == props.groupId; });

const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndex, setSelectedIndex] = React.useState(null);
const open = Boolean(anchorEl);
const handleClickListItem = (event) => {
setAnchorEl(event.currentTarget);
};

const handleMenuItemClick = (event, index) => { setSelectedIndex(index);  setAnchorEl(null);  };

let optionsList = [];
for (let i = 0; i < menuList.length; i++) {  optionsList.push(<Link key={menuList[i].id+'optionsList'} onClick={ () => { window.topTop(); props.handleDrawerClose ? props.handleDrawerClose() : null; }} to={{ pathname: `/cat/${props.groupId}/${menuList[i].inid == 0 ? menuList[i].itemId : menuList[i].inid}/${menuList[i].string1}` }}  state={{ pageTitle : `${menuList[i].string1} ${props.groupId ==  118 ? ' VPS' : ''} ${props.groupId ==  117 ? ' Web Hosting' : ''}`, }} className="primaryMenu">{menuList[i].string1}</Link>); }
const handleClose = () => { setAnchorEl(null); };
//return optionsList;
//}

return (
<div>
<List
component="nav"
aria-label="Device settings"
sx={{ backgroundColor:'#18AB0D', color:'#ffffff', margin:0, padding:0, }}
>
<ListItemButton
id="lock-button"
aria-haspopup="listbox"
aria-controls="lock-menu"
aria-label="primary menu"
aria-expanded={open ? 'true' : undefined}
onClick={handleClickListItem}
sx={{ margin:0, paddingTop:0.5, paddingBottom:0.5,  paddingLeft:2, paddingRight:2, }}

>
<ListItemText
disableTypography
primary={<Typography variant="body2" style={{ color: '#FFFFFF', fontSize:12, textTransform:'uppercase', }}>{props.title ? props.title : null}</Typography>}

/> <ArrowDropDownIcon />
</ListItemButton>
</List>
<Menu
id="lock-menu"
anchorEl={anchorEl}
open={open}
onClose={handleClose}
MenuListProps={{
'aria-labelledby': 'lock-button',
role: 'listbox',
}}
>
{optionsList.map((option, index) => (
<MenuItem
key={window.uuidv4()}
selected={index === selectedIndex}
onClick={(event) => handleMenuItemClick(event, index)}
>
{option}

</MenuItem>
))}
</Menu>
</div>
);
}


function Currency(props) {
//const getCurrency = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'6' }, fetchPolicy: "cache-and-network" }); 
const currencies = [];
const flags = [];

const [formState, setFormState] = useState({
curIndex: ((typeof props.curIndex !== 'undefined') ? props.curIndex : 130),
});



const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndex, setSelectedIndex] = React.useState((typeof props.curIndex !== 'undefined') ? props.curIndex : 130);
const open = Boolean(anchorEl);
const handleClickListItem = (event) => {
setAnchorEl(event.currentTarget);
};

const handleMenuItemClick = (event, index) => {
setSelectedIndex(index);
setAnchorEl(null);
setFormState({ ...formState, curIndex: index  });
props.setCountry(index);
};


if(props.currency){
const numbers = props.currency.getSiteList;
for (let i = 0; i < numbers.length; i++) {   
currencies[(numbers[i].inid == 0) ? numbers[i].itemId : numbers[i].inid] = numbers[i].string1;
flags[(numbers[i].inid == 0) ? numbers[i].itemId : numbers[i].inid] = numbers[i].string2;
}
}

const options = currencies;
const handleClose = () => {
setAnchorEl(null);
};

return (
<div className='secondaryMenu'>
  <List 
	component="nav"
	aria-label="Device settings"
    sx={{ backgroundColor:'#0080C0', minWidth:100, color:'#ffffff', margin:0, padding:0, fontSize:50, }}
  >
	<ListItemButton
	  id="lock-button"
	  aria-haspopup="listbox"
	  aria-controls="lock-menu"
	  className='currency'
	  aria-label="currency"
	  aria-expanded={open ? 'true' : undefined}
	  onClick={handleClickListItem}
	  sx={{ color:'#ffffff', margin:0, paddingTop:0, paddingBottom:0,  paddingLeft:2, paddingRight:2, }}
	>
	  <ListItemText
	//	secondary={options[selectedIndex]}
		disableTypography
  //      primary={<Typography variant="body2" style={{ color: '#FFFFFF' }}>MyTitle</Typography>}
        secondary={<Typography variant="body2" style={{ color: '#FFFFFF' }}>
		<span className='px-1'><img src={`${window.apiServer}/resource/${flags[formState.curIndex]}`} className='currencyImg mx-1' /></span><span dangerouslySetInnerHTML={{__html: options[selectedIndex]}}></span>
		</Typography>}
/><ArrowDropDownIcon />
	</ListItemButton>
  </List>
  <Menu
	id="lock-menu"
	anchorEl={anchorEl}
	open={open}
	onClose={handleClose}
	MenuListProps={{
	  'aria-labelledby': 'lock-button',
	  role: 'listbox',
	}}
 >
	{options.map((option, index) => (
	  <MenuItem
		key={option}
		className='currency'
		selected={index === selectedIndex}
		onClick={(event) => handleMenuItemClick(event, index)}
 >
	<span className='px-1'><img src={`${window.apiServer}/resource/${flags[index]}`} className='currencyImg mx-1' /></span><span dangerouslySetInnerHTML={{__html: option}}></span>
	  </MenuItem>
	))}
  </Menu>
</div>
);
}

function DeleteData(props){
return (<section className='text-end'><Form type="form" formmode={2} rowid={props.rowid} viewid={32} updateDelete={props.updateDelete} /></section> );
}


function curDate(){
const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();
return (`${day}/${month}/${year}`);
}


function CarTTable(props) {
const [formState, setFormState] = useState({
paymentStatus:false,
});
//const getTickets = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(143), arg1:('12') }, fetchPolicy: "cache-and-network"});
//if (getTickets.loading) {return <LoadingSK />;}
//if (getTickets.error) {return `Error! ${getTickets.error.message}`; }
/*
if (getTickets.data) {
const numbers = getTickets.data.getSiteList;
const listItems = numbers.map(number =>
<option key={'getTickets'+number.id} value={number.inid != 0 ? number.inid : number.itemId}> 
{number.string1} {number.inid != 0 ? number.inid : number.itemId}</option>);}
*/
let cartItems = '';
let listCartItems ='';
let totalAmount =0;

function hideElem(x){ if(document.getElementById(x)){document.getElementById(x).style.display = 'none'}}

if(props.cartItems && props.cartItems.length > 0){
cartItems = props.cartItems;
for (let i = 0; i < props.cartItems.length; i++) { totalAmount = totalAmount+(Number(cartItems[i].total))*(Number(cartItems[i].description));  }


listCartItems = cartItems.map((number, index) => 
<tr key={'cartitems'+number.id} id={'cartitems'+number.id}>
  <td className='editcol'>
<span className="pointer" onClick={() => { hideElem('cartitems'+number.id)}}>
<Form type="form" rowid={number.inid == 0 ? number.itemId : number.inid} viewid={29}  updateCart={props.updateCart} />
</span></td>
  <td>  {number.province}  </td>
  <td>
  
  <Form type="form" total={number.total} rowid={number.inid == 0 ? number.itemId : number.inid} updateCart={props.updateCart} viewid={18} typeid={number.planid} formmode={1} />
  
  </td>
  <td><GetUOM id={2} arg1='UOM' arg2={number.planid} /></td>
  <td><AddToCart type='server' currency={props.currency}  price={Number(number.description)} /></td>
  <td><AddToCart type='server' currency={props.currency}  price={(Number(number.total))*(Number(number.description))} /></td>
</tr>
);}

function updatePayment(){
if(formState.paymentStatus != true){setFormState({ ...formState, paymentStatus: true });
}}

let userEmail='';
if(sessionStorage.getItem("signIn")){
let decoded = jwtDecode(sessionStorage.getItem("signIn"));
userEmail=decoded.email;} 


let userId = 0;
if(sessionStorage.getItem("signIn")){
let decoded = jwtDecode(sessionStorage.getItem("signIn"));
userId=decoded.userId;
}
const year = new Date().getFullYear().toString().substr(-2);
const rand4 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
let useInvoice = (`${year}${userId}${rand4}`);

return ( 
<section className='mb-5'> 
  
{formState.paymentStatus == false ? 
<><section className='pageTitle pb-1'>
<section className="d-flex align-items-end">
<h2 className=''>
<span className="mx-2"><AddShoppingCartIcon sx={{ marginRight:2, fontSize:35, }} />Cart Items 
</span>
</h2>
<section className="flex-grow-1 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse align-items-center">
{Number(totalAmount) > 0 ? <section className=''>
  
  
  
    
<section className="flex-grow-1 d-flex flex-column justify-content-start">
<section className="flex-fill d-flex flex-row-reverse align-items-start fw-bold">
<section>

<section className="d-flex transDetail pb-1 mb-1">
<section className="">Invoice ID:</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end">{useInvoice}</section>
</section>



<section className="d-flex transDetail pb-1 mb-1">
<section className="">Amount:</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end"><AddToCart  type='server' currency={props.currency}  price={totalAmount} /></section>
</section>


<section className="d-flex transDetail pb-1 mb-1">
<section className="">VAT:</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end">0.00</section>
</section>

<section className="d-flex transDetail pb-1 mb-1">
<section className="">Total</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end"><AddToCart reverse type='server' currency={props.currency}  price={totalAmount} /></section>
</section>



<section className="d-flex">
<section className="">Date</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end">{curDate()}</section>
</section>
</section>
</section>
</section>
</section> : null}
</section>
</section>
</section>
</section>



<table className="customers mt-2">
<thead>
  <tr>
    <th className='editCol'>&nbsp;</th>
    <th>Item</th>
    <th>Qty</th>
    <th>UOM</th>
    <th>Rate</th>
    <th>Amount</th>
  </tr>
</thead>
<tbody>{listCartItems}</tbody>
</table>

<section className="d-flex flex-row-reverse mt-3 fw-bold consultancy p-1 pt-2 pb-2">
<span className="px-2"><AddToCart type='server' currency={props.currency}  price={totalAmount} /></span>
<span className="px-2 text-center">Total</span>
</section>



{(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? <center className='mt-5 mb-5'><Link onClick={ () => window.topTop() } to={{ pathname: `/acct/109/Login`, }}  state={{ pageTitle : 'Sign in' }}><button type="button" className="btn btn-primary mt-4 mb-4 btn-sm centerText">Login or Create account to make payment</button></Link></center> : null}




{(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? null : 

<>{Number(totalAmount) > 0 ? <section className='mt-5 mb-5'>
<section className='row'>
<section className='col-md-6 px-3 text-center'>
<Typography className='mb-3 fw-bold'>Pay with PayPal, Venmo or Credit Card</Typography>
<section>
<Payment updateCart={props.updateCart} closePayment={updatePayment} invdate={curDate()} invoice={useInvoice} amount={totalAmount} />
</section>
</section>
<section className='col-md-6 px-3'>
  
<section className="paymentSection p-3">
<Typography className='mb-3 fw-bold'>Pay by Bank Transfer</Typography>


<Typography className='mb-3'>
You can transfer funds into your account wallet and make payments with it. Ensure to transfer the amount you intend to use as refund for unused funds might take time and incur processing fees
</Typography>
<Typography className='mb-3'>
To transfer money, please follow the instructions below
</Typography>

<section className='mb-3'>
<p className='fw-bold'>International transfer (US Dollar)</p>
<p>Account name: Dredge Resources Limited</p>
<p>Account no. 3003472629</p>
<p>Bank: United Bank for Africa PLC.</p>
<p>Country: Nigeria</p>
<p className='fw-bold'>Payment Instruction, please inform your bank as follows: </p>  
<p>Pay: UNITED BANK FOR AFRICA PLC, NIGERIA </p>           
<p>SWIFT CODE: UNAFNGLA    </p>
           
<p>Through: CITIBANK NEW YORK , UNITED STATES  </p>                           
<p>SWIFT CODE:      CITIUS33    </p>                        
<p>ROUTING NO:     021000089 </p>                          
<p>ACCOUNT NUMBER:  36320321 </p>     

<p>FOR FURTHER CREDIT:   </p>     
<p>Beneficiary account number: 3003472629</p>
<p>Beneficiary name: Dredge Resources Limited</p>
<p>Payer: {userEmail} (wallet)</p>



</section>
<section className='mb-3 mt-5'>
<p className='fw-bold'>Local transfer (Nigeria customers only)</p>
<p>Bank name: Access Bank PLC</p>
<p>Account number: 0000971807</p>
<p>Account name: Dredge Resources Limited</p>
<p>Payer: {userEmail} (wallet)</p>
</section>
</section>



</section>
</section>   
</section> : null}</>}



</> : null}
{formState.paymentStatus == true ? 
  <section>
    <section className='mx-5 mb-3 text-center'><center><img loading='lazy' src={PaySuccess} className="img-fluid checkImg" /></center></section>
    <h5 className='mb-3 text-center'>Payment Confirmation - Thank You!</h5>
    <Typography className='mb-3 text-center'>Thank you for your payment! We’re pleased to confirm that your transaction is successful.</Typography>
    <Typography className='mb-3 text-center'>You can now enjoy your service, If you have any questions or need assistance, feel free to reach out to our support team at support@dredge.com.ng.</Typography>
    <Typography className='mb-5 text-center'>Thank you for choosing us!</Typography>
    
    <section className="mt-5 text-center fw-bold mb-5 mx-5 alert alert-info" role="alert">It might take some minutes for your purchase(s) to be ready</section>
    
    <Typography className='mb-3 text-center fw-bold'>

Best regards,  <br />
Dredge Resources Limited<br />
sales@dredge.com.ng  
</Typography>
   </section> : null }
</section>
);
}

function PageTitle(props){ return <h3 className='pageTitle m-0 p-0 pb-2'>{props.title}</h3>	}
function CustomTabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div
role="tabpanel"
hidden={value !== index}
id={`simple-tabpanel-${index}`}
aria-labelledby={`simple-tab-${index}`}
{...other}
>
{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
</div>
);
}

CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
id: `simple-tab-${index}`,
'aria-controls': `simple-tabpanel-${index}`,
};
}

function ListData(props) { 
const { data } = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(5), arg1:'5' }, fetchPolicy: "cache-and-network"});

const [formState, setFormState] = useState({
deleteItems: '', deleteBtn:0, openDetails:false, display:props.display, 
fullname: ((typeof props.fullname !== 'undefined') ? props.fullname : ""),
gender: ((typeof props.gender !== 'undefined') ? parseInt(props.gender) : 0),
username:((typeof props.username !== 'undefined') ? props.username : ""),
address: ((typeof props.address !== 'undefined') ? props.address : ""),
city:((typeof props.city !== 'undefined') ? props.city : ""),
province:((typeof props.province !== 'undefined') ? props.province : ""),
zip:((typeof props.zip !== 'undefined') ? props.zip : ""),
country:((typeof props.country !== 'undefined') ? parseInt(props.country) : 0),
title:((typeof props.title !== 'undefined') ? props.title : ""),
display:1,//list data, 2 load form
});

function loadForm(x){ setFormState({ ...formState, display: x }); }

if (data) { 
if(data.getSiteList.length > 0){
if(formState.title != data.getSiteList[0].title){
setFormState({ ...formState, 
fullname: data.getSiteList[0].fullname,
gender: data.getSiteList[0].gender,
username: data.getSiteList[0].username,

address: data.getSiteList[0].address,
city: data.getSiteList[0].city,
province: data.getSiteList[0].province,
zip: data.getSiteList[0].zip,
country: data.getSiteList[0].country,
title: data.getSiteList[0].title,
});}}
}


const [value, setValue] = React.useState(0);
const handleChange = (event, newValue) => { setValue(newValue); };


useEffect(() => { setValue(0) }, [props.display]);
const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
let useKeys = [];


const currFields = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(134), arg1:'1' }, fetchPolicy: "cache-and-network" }); 
const getData = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(134), arg1:'1' }, fetchPolicy: "cache-and-network" }); 





var userId = 0;
if(sessionStorage.getItem("signIn")){
const decoded = jwtDecode(sessionStorage.getItem("signIn"));
userId = decoded.userId;}

var userId = 0
if(sessionStorage.getItem("signIn")){
const decoded = jwtDecode(sessionStorage.getItem("signIn"));
userId = decoded.userId;}

let contentDisplay;
if(parseInt(props.display) == 4){contentDisplay = (<section><AccountSecurity /></section>);}
else if(parseInt(props.display) == 5){contentDisplay = 
(<>{parseInt(formState.country)} {parseInt(formState.gender)}<Form type="form" viewid={5} tbl={5} formmode={2} 
  
  fullname={formState.fullname}
gender={parseInt(formState.gender)}
username={formState.username}

address={formState.address}
city={formState.city}
province={formState.province}
zip={formState.zip}
country={parseInt(formState.country)}
title={formState.title}

/></>);}
else if(parseInt(props.display) == 143){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	{curUser.typeId}</span>
    <span className="flex-fill col-md-3 p-2">Date</span>
    <span className="flex-fill col-md-5 p-2">Subject</span>
    <span className="flex-fill col-md-3 p-2 text-center">Status</span>
    </h6></>);}
    
else if(parseInt(props.display) == 141){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-2 p-2">Date</span>
    <span className="flex-fill col-md-3 p-2 text-center">Invoice ID</span>
    <span className="flex-fill col-md-3 p-2 text-center">Amount</span>
    <span className="flex-fill col-md-3 p-2 text-center">Status</span>
    </h6></>);}
    
    
else if(parseInt(props.display) == 139){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-3 p-2">Due Date</span>
    <span className="flex-fill col-md-3 p-2">Invoice ID</span>
    <span className="flex-fill col-md-3 p-2 text-center">Amount</span>
    <span className="flex-fill col-md-2 p-2 text-center">Status</span>
    </h6></>);}
    
else if(parseInt(props.display) == 140){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-3 p-2">Expired Date</span>
    <span className="flex-fill col-md-5 p-2">Item</span>
    <span className="flex-fill col-md-3 p-2 text-center">Status</span>
    </h6></>);}
      
else if(parseInt(props.display) == 116){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-4 p-2">Domain</span>
    <span className="flex-fill col-md-3 p-2">Created</span>
    <span className="flex-fill col-md-2 p-2">Expires</span>
    <span className="flex-fill col-md-2 p-2 text-center">Status</span>
    </h6>
    {listMyDomains}</>);}
         
else if(parseInt(props.display) == 117){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-4 p-2">Package title</span>
    <span className="flex-fill col-md-3 p-2">Created</span>
    <span className="flex-fill col-md-2 p-2">Expires</span>
    <span className="flex-fill col-md-2 p-2 text-center">Status</span>
    </h6></>);}
    
else if(parseInt(props.display) == 118){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-4 p-2">Package title</span>
    <span className="flex-fill col-md-3 p-2">Created</span>
    <span className="flex-fill col-md-2 p-2">Expires</span>
    <span className="flex-fill col-md-2 p-2 text-center">Status</span>
    </h6></>);}
    
else if(parseInt(props.display) == 120){contentDisplay = 
  (<><h6 className="d-flex header fw-bold p-2">
    <span className="flex-fill col-md-1 p-2">#	</span>
    <span className="flex-fill col-md-4 p-2">Package title</span>
    <span className="flex-fill col-md-3 p-2">Created</span>
    <span className="flex-fill col-md-2 p-2">Expires</span>
    <span className="flex-fill col-md-2 p-2 text-center">Status</span>
    </h6></>);}
else {contentDisplay = (<div>
<Box sx={{ width: '100%' }}>
{props.dataimport && props.dataimport == 1 && userId ==1 ?
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
{props.addnew && props.addnew == 1 ? <Tab label="Add new" {...a11yProps(1)} /> : null}
{props.manage && props.manage == 1 ? <Tab label="Manage fields" {...a11yProps(2)} /> : null}
{props.dataimport && props.dataimport == 1 ? <Tab label="Import data" {...a11yProps(3)} /> : null}
{props.dataexport && props.dataexport == 1 ? <Tab label="Export data" {...a11yProps(4)} /> : null}
</Tabs>
</Box> : 

props.dataimport && props.dataimport == 0 && props.manage && props.manage == 1 && userId ==1 ?
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
{props.addnew && props.addnew == 1 ? <Tab label="Add new" {...a11yProps(1)} /> : null}
{props.manage && props.manage == 1 ? <Tab label="Manage fields" {...a11yProps(2)} /> : null}
</Tabs>
</Box> : 

props.dataimport && props.dataimport == 0 && props.manage && props.manage == 0 && props.addnew == 1 && userId ==1 ?
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
{props.addnew && props.addnew == 1 ? <Tab label="Add new" {...a11yProps(1)} /> : null}
</Tabs>
</Box> : userId != 1 ?
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
</Tabs>
</Box> : null}
{parseInt(value) == 0 ? <section className='mt-2'><DataTable updateDisplay={props.updateDisplay} tbl={props.display} /></section> : null}

{parseInt(value) == 1 ? 
<CustomTabPanel value={value} index={1}>
<Form menu={props.menu} type="form" display={props.display} viewid={2} tbl={props.display} formmode={1} />
</CustomTabPanel> : null}

{parseInt(value) == 2 ? 
<CustomTabPanel value={value} index={2}>
<section className='row'>
<section className='col-md-6 mb-3'><DataTable editState={-2} updateDisplay={props.updateDisplay} field={true} tbl={props.display} /></section>
<section className='col-md-6 mb-3'><Form display={props.display} menu={props.menu} type="form" viewid={1} tbl={props.display} formmode={1} /></section>
</section>
</CustomTabPanel> : null}


{parseInt(value) == 3 ? 
<CustomTabPanel value={value} index={3}>
imports data {value}
</CustomTabPanel> : null}


{parseInt(value) == 4 ? 
<CustomTabPanel value={value} index={4}>
exports data {value}
</CustomTabPanel> : null}
</Box>
</div>);}

return (<><Typography className='innerPageTitle p-3 fw-bold'>
<span className="d-flex align-items-center">
<span className="">{props.pageTitle}</span>
<span className="flex-fill d-flex flex-row-reverse align-items-center">
{parseInt(curUser.typeId) != 0 && parseInt(props.display) == 143 ? <><AddIcon /> <span className='mx-1 pointer' onClick={() => { loadForm(2)}}>Create Ticket</span></> : null}</span>
</span>  
</Typography>
<section>
</section>

{parseInt(formState.display) == 1 ? contentDisplay: null}
{parseInt(formState.display) == 2 ? <Form handleCancel={loadForm} viewid={2} formmode={1} tbl={138} menu={props.menu} products={props.products} display={props.display} type='form' />: null}
</>);}




function Restricted(){
return ( 
<section className='defMrg8'>
<section className='defMrg8 m-2 mb-4 px-3'>


<section className='row'>
<section className='col-md-6 notfound p-4 mb-4'>
<section className='mt-5 mb-5 notfound pt-5 pb-5'>
<section className="d-flex align-items-center justify-content-center pt-2 p-2">
<section className=' d-flex flex-column mx-2 p-1 px-4 p-3'>
<h3>Login Required!</h3>
<p>Please login to access the requested service</p>
</section>


<section className="flex-grow-1 d-flex flex-column justify-content-start align-items-start align-self-start logoTopCon">
<img src={LogInBG} className='img-fluid' />
</section>
</section>
</section>
</section>

<section className='col-md-6 mb-4'><LogIn /></section>
</section>
</section> 
</section> 
);
}

function GetPageTitles(props) {
let listItems='';
const numbers = props.pages.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == (props.menuId ? props.menuId : 0); });
const numbersProducts = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117 || parseInt(currItem.string5) == 118; });

const numbersServices = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 120; });

if(parseInt(props.menuId) != 133){
listItems = numbers.map(number =><Link key={`listItems12${number.id}`} onClick={ () => window.topTop() } to={{ pathname: `/con/${number.inid == 0 ? number.itemId: number.inid}/${number.tbl}/${number.string1}/${props.menuId ? props.menuId : ''}`, }}  state={{ pageTitle : number.string1, string2:number.string2 }} className={props.classes}>{number.string1}</Link>);
}
if(parseInt(props.menuId) == 133){
listItems = numbersProducts.map(number =><Link key={number.id+'listfootproducts'} onClick={ () => window.topTop() } to={{ pathname: `/cat/${number.string5}/${number.inid == 0 ? number.itemId: number.inid}/${number.string1}/${props.menuId ? props.menuId : ''}`, }}  state={{ pageTitle : `${number.string1} ${number.string5 == 117 ? ' Web Hosting' : ''} ${number.string5 == 118 ? ' VPS' : ''}`, string2:number.string2 }} className={props.classes}>{number.string1} {parseInt(number.string5) == 118 ? ' VPS' : null}	{parseInt(number.string5) == 117 ? ' Web Hosting' : null}																					   </Link>);
}



let listServices= numbersServices.map(number =><Link key={number.id+'listfootserverivces'} onClick={ () => window.topTop() } to={{ pathname: `/cat/${number.string5}/${number.inid == 0 ? number.itemId: number.inid}/${number.string1}/${props.menuId ? props.menuId : ''}`, }}  state={{ pageTitle : number.string1, string2:number.string2 }} className={props.classes}>{number.string1}</Link>);


return <>{props.menuId && props.menuId == 125 ? listServices : null}{listItems}</>;
}


function AccountSecurity() {
const [formState, setFormState] = useState({
status:0,
recoveryEmail:'',
supportPIN:'',
data:'',
});


const [expanded, setExpanded] = React.useState(null);
const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);
};

const { loading, error, data, refetch } = useQuery(DEF_SITE_LIST, { variables: { listid: 34, arg1:'34' }, fetchPolicy: "cache-first"});



if (data) {
if(data.getSiteList.length > 0 && formState.data != data.getSiteList){
setFormState({ ...formState, 
status:data.getSiteList[0].status && data.getSiteList[0].status == 1 ? 1 : 2,
recoveryEmail:data.getSiteList[0].username ? data.getSiteList[0].username : '',
supportPIN:data.getSiteList[0].total ? data.getSiteList[0].total : null,
data:data.getSiteList,
})
}}



 const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
return (
<div style={{maxWidth:'100%',}}>
 
<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>Change Password</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Form viewid={33} formmode={2} type='form' />
</AccordionDetails>
</Accordion> 


<Accordion expanded={expanded === 'panel1A'} onChange={handleChange('panel1A')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1Abh-content"
id="panel1Abh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>Support PIN</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Form viewid={37} total={formState.supportPIN} refetch={refetch} rowid={-2} formmode={2} type='form' />
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel2bh-content"
id="panel2bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Enable 	Two (2) Authentications
</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Typography>
Require One Time Passphrase (OTP) for account login: 
<Form type="form" refetch={refetch} status={formState.status} formmode={2} viewid={34} />
</Typography></AccordionDetails>
</Accordion>



<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel3bh-content"
id="panel3bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Account Recovery Email</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
><Form type="form" refetch={refetch} formmode={2} username={formState.recoveryEmail} rowid={-2} viewid={35} /></AccordionDetails>
</Accordion>



<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel4bh-content"
id="panel4bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Notifications</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
></AccordionDetails>
</Accordion>




<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel5bh-content"
id="panel5bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Cancel Account</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
><Form type="form" formmode={2} rowid={-2} viewid={36} /></AccordionDetails>
</Accordion>

</div>);}
function LoadingSK() {
return (
<Box sx={{ width: '100%' }}>
<Skeleton height={6} className='mb-2' animation="wave" sx={{ animationDuration: "0.3s", backgroundColor: 'white', '&::after': { backgroundColor: '#EEF0F1', }, }} />
<Skeleton height={6} className='mb-2' animation="wave" sx={{ animationDuration: "0.3s", backgroundColor: 'white', '&::after': { backgroundColor: '#EEF0F1', }, }} />
<Skeleton height={6} className='mb-2' animation="wave" sx={{ animationDuration: "0.3s", backgroundColor: 'white', '&::after': { backgroundColor: '#EEF0F1', }, }} />
</Box>
);
}
  
function AddToCart(props) {
const country = useContext(Country);
let itemPrice = <LoadingSK />;

function formater(x=0){
const options = {
style: 'decimal',  // Other options: 'currency', 'percent', etc.
minimumFractionDigits: 2,
maximumFractionDigits: 2,
};
let formattedWithOptions = x.toLocaleString('en-US', options);
return (formattedWithOptions);
};
const curSym = props.currency.getSiteList.filter(function(currItem) { return parseInt(currItem.itemId) == country || parseInt(currItem.inid) == country; });

if(props.type && props.type == 'domain'){
if(props.cart){
itemPrice = <section className='text-center fw-bold pointer'>
<h4 className='mb-4'>
<span dangerouslySetInnerHTML={{__html: curSym[0].string4}}></span><span>
{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))} / <GetUOM id={2} arg1='UOM' arg2={props.uom} /></span></h4>
<section className='mt-3'>
<Form type="form" tbl={props.groupid} city={window.cartCookie} rowid={props.rowid} title={props.itemId} total={1} province={props.prdname} viewid={28} description={props.price} formmode={1} updateCart={props.updateCart} planid={props.uom} />
</section>
</section>;}

if(!props.cart){
itemPrice = <><span dangerouslySetInnerHTML={{__html: (curSym.length > 0 ? curSym[0].string4 : '')}}></span><span>
{props.price ? (formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))) : null} / <GetUOM id={2} arg1='UOM' arg2={props.uom ? props.uom : null} /></span></>;;
}}


if(props.type && (props.type == 'hosting' || props.type == 'server')){
if(props.cart){
itemPrice = <section className='text-center fw-bold pointer'>
<h4 className='mb-4'>
<span dangerouslySetInnerHTML={{__html: (curSym.length > 0 ? curSym[0].string4 : '')}}></span><span>
{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))} / <GetUOM id={2} arg1='UOM' arg2={props.uom} /></span></h4>
<section className='mt-3'>
<Form type="form" tbl={props.groupid} city={window.cartCookie} rowid={props.rowid} title={props.itemId} total={1} province={props.prdname} viewid={28} description={props.price} formmode={1} updateCart={props.updateCart} planid={props.uom} />
</section>
</section>;
}
if(!props.cart){
if(!props.reverse){
itemPrice = (<><span dangerouslySetInnerHTML={{__html: (curSym.length > 0 ? curSym[0].string4 : '')}}></span><span>{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))}</span></>);
}
if(props.reverse){
itemPrice = (<><span>{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))}</span><span dangerouslySetInnerHTML={{__html: curSym[0].string4}}></span></>);
}}}




return (itemPrice);
}
  
  
  
function ProductFeatures(props) {
const features = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:String(23) }, fetchPolicy: "cache-and-network" }); 
let pFeatures = <LoadingSK />;
let col = '';
if(props.col){col='col-12 col-md-6 col-lg-3 px-3 mb-3';}

if(features.error){pFeatures = <section className="alert alert-warning offline" role="alert">Connection Error! {features.error.message}</section>;} 
if(features.data){
let numbers = features.data.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == props.gId; });  
pFeatures = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<section key={number.id} className={col}>
<Typography className="mb-3 fw-bold">{number.string1}</Typography>
<section className="mb-1" dangerouslySetInnerHTML={{__html: number.string5}}></section> 
<Divider className="mb-2" />
</section> 
);}
return ( pFeatures );
}

function RigthPromo(props) { return ''; }
function Settings(props){
const getSettings = useQuery(DEF_SITE_LIST, {variables: { listid: parseInt(props.featureid), arg1:props.type, arg2:Number(props.productid) }, fetchPolicy: "cache-and-network" }); 

let listItems = '';
if(getSettings.data){
if(getSettings.data.getSiteList.length > 0){
console.log(getSettings.data.getSiteList.length);
console.log(getSettings.data.getSiteList);
listItems =  (<>st 
<Form type="form" title={props.type} planid={props.productid} label='' itemstatus={getSettings.data.getSiteList[0].total} status={getSettings.data.getSiteList[0].total} formmode={2} tbl={parseInt(props.featureid)} viewid={parseInt(props.featureid)} rowid={getSettings.data.getSiteList[0].inid == 0 ? getSettings.data.getSiteList[0].itemId : getSettings.data.getSiteList[0].inid} id={getSettings.data.getSiteList[0].inid == 0 ? getSettings.data.getSiteList[0].itemId : getSettings.data.getSiteList[0].inid} refetch={getSettings.refetch} />
	ROWID = {getSettings.data.getSiteList[0].inid == 0 ? getSettings.data.getSiteList[0].itemId : getSettings.data.getSiteList[0].inid}
	TOTAL = {getSettings.data.getSiteList[0].total}
	</>); } else {listItems = (<>st 
<Form type="form" title={props.type} planid={props.productid} label='' formmode={1} tbl={parseInt(props.featureid)} viewid={parseInt(props.featureid)} refetch={getSettings.refetch} />
</>)}
return (<>{listItems}</>);
}
}
function DomainMgt(props){
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);};
let menu = '';
let numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.total) === 116 || parseInt(currItem.total) === -1; });
if(numbers.length > 0){
menu = numbers.map((number, index) => <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} key={`domainmgt${number.id}`}>
<AccordionSummary 
aria-controls={"panel"+index+"bh-content"}
id={"panel"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title}  {parseInt(number.inid) === 0 ? number.itemId : number.inid}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'>{number.description}</section>
<section>{props.id} fid {parseInt(number.inid) === 0 ? number.itemId : number.inid}
<Settings type='domain' productid={props.id} featureid={parseInt(number.inid) === 0 ? number.itemId : number.inid} />
</section>
</AccordionDetails></Accordion>
);}
return (
<section className='mx-5'>
<section className='pageTitle1 pb-2'>Manage {props.title}</section>{menu}</section>);
}


function ServerMgt(props){
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);};
let menu = '';let features = '';

let numbersFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) === 0 || parseInt(currItem.planid) === 118; });
if(numbersFeatures.length > 0){
features = numbersFeatures.map((number, index) => <Accordion expanded={expanded === 'panelFS'+index} onChange={handleChange('panelFS'+index)} key={`hostingmgtS${number.id}`}>
<AccordionSummary 
aria-controls={"panelFS"+index+"bh-content"}
id={"panelFS"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title} features {number.itemId}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className=''>details</section></AccordionDetails></Accordion>
);
}



let numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.total) === 118 || parseInt(currItem.total) === -1 || parseInt(currItem.total) === -4 || parseInt(currItem.total) === -3; });
if(numbers.length > 0){
menu = numbers.map((number, index) => <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} key={`servermgt${number.id}`}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"panel"+index+"bh-content"}
id={"panel"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title} main {number.itemId}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'>{number.description}</section>
<section className=''>details</section></AccordionDetails></Accordion>
);
}
return (
<section className='mx-5'>
<section className='pageTitle1 pb-2'>Manage {props.title}</section>
{features}
{menu}</section>);
}
function HostingMgt(props){
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);};
let menu = ''; let features = '';

let numbersFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) === 0 || parseInt(currItem.planid) === 117; });
if(numbersFeatures.length > 0){
features = numbersFeatures.map((number, index) => <Accordion expanded={expanded === 'panelF'+index} onChange={handleChange('panelF'+index)} key={`hostingmgt${number.id}`}>
<AccordionSummary 
aria-controls={"panelF"+index+"bh-content"}
id={"panelF"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title} feature {number.itemId} ID {props.id}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className=''>details</section></AccordionDetails></Accordion>
);
}


let numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.total) === 117 || parseInt(currItem.total) === -1 || parseInt(currItem.total) === -2 || parseInt(currItem.total) === -4; });
if(numbers.length > 0){
menu = numbers.map((number, index) => <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} key={`hostingmgt${number.id}`}>
<AccordionSummary 
aria-controls={"panel"+index+"bh-content"}
id={"panel"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title} main {number.itemId}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'>{number.description}</section>
<section className=''>details</section></AccordionDetails></Accordion>
);
}
return (
<section className='mx-5'>
<section className='pageTitle1 pb-2'>Manage {props.title}</section>
{features}
{menu}</section>);
}

//export { NotFound, SimpleCard}
export {NotFound, GetPageTitles, CarTTable, ListData, SocialConnect, LoadingSK, SideMenu, RigthPromo, Currency, PageTitle, Restricted, AccountSecurity, ProductFeatures, AddToCart, DeleteData, DropMenu, DataTable, GetUOM, DomainMgt, ServerMgt, HostingMgt}
